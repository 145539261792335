import { IRoute } from "../../interfaces/others/IRoute";

export const Routes: IRoute[] = [
  {
    name: "Solicitudes",
    to: "/solicitudes",
    activeKey: "solicitudes",
    validRoles: [
      "Administrador",
      "Lider de sucursal",
      "Reclutador",
      "Administrador-", 
    ],
    icon: "rectangle-list",
  },
  {
    name: "Colaboradores",
    to: "/colaboradores",
    activeKey: "colaboradores",
    validRoles: [
      "Administrador",
      "Lider de sucursal",
      "Contador",
      "Talento humano",
      "Reclutador",
      "Administrador-", 
    ],
    icon: "people-group",
  },
  {
    name: "Calendario",
    to: "/calendar",
    activeKey: "calendar",
    validRoles: ["Administrador", "Reclutador", "Administrador-"],
    icon: "calendar",
  },
  {
    name: "Vacantes",
    to: "/vacantes",
    activeKey: "vacantes",
    validRoles: ["Administrador", "Administrador-", "Reclutador"],
    icon: "id-card",
  },
  {
    name: "Puestos",
    to: "/puestos",
    activeKey: "puestos",
    validRoles: ["Administrador", "Administrador-"],
    icon: "check-to-slot",
  },
  {
    name: "Plantillas",
    to: "/plantillas",
    activeKey: "plantillas",
    validRoles: ["Administrador", "Administrador-"],
    icon: "file",
  },
  {
    name: "Sucursales",
    to: "/sucursales",
    activeKey: "sucursales",
    validRoles: ["Administrador", "Administrador-", "Líder de líderes"],
    icon: "store",
  },
  {
    name: "Lista negra",
    to: "/lista-negra",
    activeKey: "lista-negra",
    validRoles: ["Administrador", "Administrador-", "Reclutador", "Talento humano"],
    icon: "address-book",
  },
  {
    name: "Usuarios",
    to: "/usuarios",
    activeKey: "usuarios",
    validRoles: ["Administrador", "Administrador-"],
    icon: "user",
  },
  {
    name: "Expediente",
    to: "/mi-expediente",
    activeKey: "expediente",
    validRoles: ["Trabajador"],
    icon: "folder-open",
  },
  // {
  //   name: "Notificaciones",
  //   to: "/notificaciones",
  //   activeKey: "notificaciones",
  //   validRoles: ["Administrador"],
  //   icon: "message",
  // },
  // {
  //   name: "Reloj checador",
  //   to: "/reloj-checador",
  //   activeKey: "reloj-checador",
  //   validRoles: ["Administrador", "Lider de sucursal"],
  //   icon: "user-check",
  // },
  // {
  //   name: "Traspasos",
  //   to: "/traspasos",
  //   activeKey: "traspasos",
  //   validRoles: ["Administrador", "Lider de sucursal"],
  //   icon: "shuffle",
  // },
  // {
  //   name: "Colaboradores sucursal",
  //   to: "/sucursal/colaboradores",
  //   activeKey: "colaboradores-sucursal",
  //   validRoles: ["Lider de sucursal"],
  //   icon: "users",
  // },
  {
    name: "Cumpleaños",
    to: "/sucursal/birthdates",
    activeKey: "birthdates",
    validRoles: ["Lider de sucursal"],
    icon: "cake-candles",
  },
  {
    name: "Horario",
    to: "/sucursal/horario",
    activeKey: "horario",
    validRoles: ["Lider de sucursal"],
    icon: "calendar-check",
  },
  // {
  //   name: "Reporte",
  //   to: "/sucursal/reporte",
  //   activeKey: "reporte",
  //   validRoles: ["Lider de sucursal"],
  //   icon: "users",
  // },
  // {
  //   name: "Reporte de plantilla",
  //   to: "/sucursal/reporte-plantilla",
  //   activeKey: "reporte-plantilla",
  //   validRoles: ["Lider de sucursal"],
  //   icon: "users",
  // },

  /* Resumen de horario */
  // {
  //   name: "Resumen de horario",
  //   to: "/sucursal/resumen-horario",
  //   activeKey: "resumen-horario",
  //   validRoles: ["Lider de sucursal"],
  //   icon: "calendar-days",
  // },
  // {
  //   name: "Asistencias",
  //   to: "/sucursal/resumen-horario-asistencia",
  //   activeKey: "resumen-horario-asistencia",
  //   validRoles: ["Lider de sucursal"],
  //   icon: "clock",
  // },
  // {
  //   name: "Asistencias",
  //   to: "/sucursal/asistencias",
  //   activeKey: "asistencias",
  //   validRoles: ["Lider de sucursal"],
  //   icon: "users",
  // },
  // {
  //   name: "Traspasos",
  //   to: "/sucursal/traspasos",
  //   activeKey: "traspasos",
  //   validRoles: ["Lider de sucursal"],
  //   icon: "users",
  // },
  
  {
    name: "Incidencias",
    to: "/sucursal/incidencias",
    activeKey: "/incidencias",
    validRoles: ["Lider de sucursal"],
    icon: "triangle-exclamation",
  },
  {
    name: "Reporte reloj",
    to: "/sucursal/reporte-reloj",
    activeKey: "/reporte-reloj",
    validRoles: ["Lider de sucursal"],
    icon: "clock",
  },


];

import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../../store';
import moment from 'moment';
import { Tooltip } from '@mui/material';
import EmptyImage from '../../../../assets/img/ic_content.svg';
import { toCamelCase } from '../../../../hooks/toCameCase.tsx';
import { useNavigate } from 'react-router-dom';
import { getCurrencyFormat } from '../../../../utils/index.ts';
import { setDefaultSort, setPage, setSortBy } from '../../../../store/slices/colaboradoresSlice.ts';

const TableColaboradores = () => {
  const { user } = useSelector((state: RootState) => state.auth);
  const { colaboradores, query, sortBy, defaultSort } = useSelector((state: RootState) => state.colaboradores);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const getAntiguedad = (date: string) => {
    const currentDate = moment();
    const recruitmentDate = moment(date);
    const years = currentDate.diff(recruitmentDate, 'years');
    recruitmentDate.add(years, 'years');
    const months = currentDate.diff(recruitmentDate, 'months');
    recruitmentDate.add(months, 'months');
    const days = currentDate.diff(recruitmentDate, 'days');

    return `${years > 0 ? years + ' años' : ''}${years > 0 && months > 0 ? ', ' : ''}${months > 0 ? months + ' meses' : ''
      }${(years > 0 || months > 0) && days > 0 ? ', ' : ''}${days > 0 ? days + ' días' : ''}`;
  };

  const getIconSort = () => {
    return (
      <i
        className={`fa-solid fa-sort-${defaultSort === "asc" ? "up" : "down"}`}
      />
    );
  };

  const setSort = (sort: string) => {
    const sortOrder =
      sortBy !== sort ? "asc" : defaultSort === "asc" ? "desc" : "asc";

      console.log('sort', sort);
    dispatch(setSortBy(sort));
    dispatch(setPage(0));

    dispatch(setDefaultSort(sortOrder));
  };

  const defaultStyle = { cursor: "pointer" };

  return (
    <div className="table-responsive mb-3">
      <table className="table table-hover table-panel text-nowrap align-middle mb-0">
        <thead>
          <tr style={{ ...defaultStyle }}>
            <th
              style={{ width: '10%' }}
              onClick={() => setSort("id")}
            >
              Id {sortBy === "id" && getIconSort()}
            </th>
            <th
              style={{ width: '22%' }}
              onClick={() => setSort("position")}
            >Posición {sortBy === "position" && getIconSort()}
            </th>
            <th
              style={{ width: '22%' }}
              onClick={() => setSort("name")}
            >Nombre {sortBy === "name" && getIconSort()}
            </th>
            <th style={{ width: '10%', cursor: 'default' }}
              // onClick={() => setSort("shift")}
            >Turno 
            {/* {sortBy === "shift" && getIconSort()} */}
            </th>
            <th style={{ width: '10%' }}
              onClick={() => setSort(!query.includes('bajas') ? "recruitmentdate" : "bandate")}
            >
              {!query.includes('bajas') ? 'Antigüedad' : 'Baja desde'} {(sortBy === "recruitmentdate" || sortBy === "bandate") && getIconSort()}
            </th>
            {user?.rol === 'Administrador' &&
            <th
              style={{ width: '10%' ,cursor: 'default'}}
            >
              Salario
            </th>
          }
            <th style={{ width: '20%' }} className='text-center'>Acciones</th>
          </tr>
        </thead>
        <tbody>
          {colaboradores && colaboradores.length > 0 ? (
            colaboradores.map((item, index) => {
              const partner = item.person;
              return (
                <tr key={index}>
                  <td>{item.id}</td>
                  <td>{item.candidate.vacancy.position.name}</td>
                  <td>{toCamelCase(`${partner.firstName} ${partner.lastName} ${partner.secondLastName}`)}</td>
                  <td>{item.candidate.shift?.name}</td>
                  <td>{
                    !query.includes('bajas') ? getAntiguedad(item.candidate.recruitmentDate) :
                      moment(item.candidate.banDate).format('DD/MM/YYYY')
                  }</td>
                  {user?.rol === 'Administrador' &&
                  <td>{getCurrencyFormat(item.candidate.monthlySalary)}</td>
                  }
                  <td className='text-center'>
                    <Tooltip
                      title={`Detalles`}
                      arrow
                    >
                      <a
                        href='#/'
                        onClick={() => navigate(`/colaboradores/detalles/${item.id}`)}
                        className="btn btn-primary m-2"
                      >
                        <span className="d-none d-sm-inline"></span>
                        <i className="fa-solid fa-eye"></i>
                      </a>
                    </Tooltip>
                  </td>
                </tr>
              )
            })
          ) : (
            <tr>
              <td colSpan={7}>
                <div className='py-4'>
                  <div className="d-flex">
                    <img src={EmptyImage} alt="" className='mx-auto w-250px' />
                  </div>
                  <h5 className='text-secondary text-center fs-20px'>No hay chilitos aún</h5>
                </div>
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  )
}

export default TableColaboradores
import React, { useEffect } from "react";
import { Partner } from "../../models/Partner";
import { useFormik } from "formik";
import CustomInput from "../formik/CustomInput.tsx";
import { notification } from "../../utils/Notifications.tsx";
import {
  saveFileProceeding,
  updateFileProceeding,
  uploadImage,
  uploadFileMotorcycle,
} from "../../services/files.service.ts";
import { getProceedingByPartner } from "../../services/proceedings.service.ts";
import FileUploader from "./FileUploader.tsx";
import { valiteCURP } from "../../utils/index.ts";
import { updateUserData } from "../../hooks/updateUserData.js";

const AddNewFile = ({
  partner,
  closeFn,
  fields,
  initialValues,
  fileTypeId,
  initialFiles,
  setLoading,
  editing,
  partnerObject,
  loadFiles,
  refetch,
  reloadPage,
}: {
  partner: Partner | number;
  closeFn: () => void;
  setLoading: (_: boolean) => void;
  fields: any;
  initialValues: any;
  fileTypeId: string | number;
  initialFiles: any;
  editing: boolean;
  loadFiles: any;
  partnerObject: any;
  refetch: any;
  reloadPage: any;
}) => {
  const [files, setFiles] = React.useState<any | null>(null);
  const [loading1, setLoading1] = React.useState(false);

  const formik = useFormik({
    initialValues: {
      [Object.keys(initialValues)[0]]:
        initialValues[Object.keys(initialValues)[0]],
    },
    onSubmit: async (values) => {
      setLoading(true);
      setLoading1(true);
      try {
        if (!files || files.length === 0) {
          notification("Error", "Por favor, seleccione una imagen", "danger");
          return;
        }

        const emptyFields = fields.filter((field) => !values[field]);

        if (emptyFields.length > 0) {
          notification("Error", "Por favor, llene todos los campos", "danger");
          return;
        }

        const proceeding = await getProceedingByPartner(partner);

        const newFiles = files.filter((file: any) => file && file.type);

        if (newFiles.length === 0) {
          notification("Error", "No hay nuevos archivos para subir", "danger");
          return;
        }

        await Promise.all(
          newFiles.map(async (file: any) => {
            if (fileTypeId === "motoDocument") {
              const motorcycleResponse = await uploadFileMotorcycle(
                partner,
                file
              );
              if (!motorcycleResponse) {
                notification(
                  "Error",
                  "Ocurrió un error al subir el documento de moto",
                  "danger"
                );
                return;
              }
              const data = {
                proceedingId: proceeding.proceeding.id,
                fileTypeId: fileTypeId,
                fileId: motorcycleResponse.id,
                fields: fields.map((field: any) => ({
                  field,
                  value: values[field],
                })),
              };
              await saveFileProceeding(data);
            } else {
              try {
                let imageId = await uploadImage(file);
                const data = {
                  proceedingId: proceeding.proceeding.id,
                  fileTypeId: fileTypeId,
                  fileId: imageId.id,
                  fields: fields.map((field: any) => ({
                    field,
                    value: values[field],
                  })),
                };
                await saveFileProceeding(data);
              } catch (error) {
                console.error(error);
              }
            }
          })
        );

        if (fields[0] === "RFC") {
          const partner = { ...partnerObject };
          await updateUserData(fields[0], partner, values);
          formik.setValues((prev: any) => ({ ...prev, RFC: values.RFC }));
        }
        //await reloadPage()

        await closeFn();
        notification("Éxito", "Documento subido correctamente", "success");
      } catch (error) {
        notification(
          "Error",
          "Ocurrió un error al subir el documento",
          "danger"
        );
        setLoading1(false);
        console.error(error);
      } finally {
        setLoading(false);
        await refetch();
        updateFileds();
        setLoading1(false);
      }
    },
    validate(values) {
      const errors: any = {};
      if (fields.includes("CURP")) {
        if (!valiteCURP(values.CURP)) {
          errors.CURP = "CURP inválido";
        }
      }
      return errors;
    },
  });

  const updateFileds = () => {
    if (Object.keys(initialValues).length !== 0) {
      const initailObject = fields.reduce((acc: any, field: any) => {
        acc[field] = initialValues[field];
        return acc;
      }, {});
      formik.setValues((prev: any) => ({ ...prev, ...initailObject }));
    } else {
      const initailObject = fields.reduce((acc: any, field: any) => {
        acc[field] = "";
        return acc;
      }, {});
      formik.setValues(initailObject);
    }

    if (fields.includes("CURP")) {
      formik.setValues((prev: any) => ({
        ...prev,
        CURP: partnerObject.person.curp,
      }));
    }

    if (fields.includes("RFC")) {
      formik.setValues((prev: any) => ({
        ...prev,
        RFC: partnerObject.person.rfc,
      }));
    }
  };

  useEffect(() => {
    updateFileds();
    setFiles(initialFiles);
  }, [initialValues, fields]);

  const getTypeInput = (field: string) => {
    return field.includes("Fecha") ? "date" : "text";
  };

  return (
    <>
      {(fields || []).map((field: string, index: number) => (
        <CustomInput
          key={index}
          formik={formik}
          field={Object.keys(initialValues)[index] || field}
          label={field}
          placeholder={field}
          sm={9}
          type={getTypeInput(field)}
          unclickable={field === "CURP"}
          required={true}
        />
      ))}
      <FileUploader
        onFilesSelected={(files: any) => setFiles(files)}
        initialFiles={initialFiles}
        fields={fields}
        closeFn={closeFn}
        refetch={refetch}
        legacyFiles={null}
      />
      <div className="d-flex justify-content-end">
        <button className="btn btn-secondary me-2" onClick={() => closeFn()}>
          Cancelar
        </button>
        <button
          disabled={!files || loading1}
          className="btn btn-azul"
          onClick={() => formik.handleSubmit()}
          type="submit"
        >
          Subir
        </button>
      </div>
    </>
  );
};

export default AddNewFile;

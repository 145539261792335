import React from "react";
import { useAppSettings } from "../../../hooks/useAppSettings.tsx";
import Layout from "../../Layout.tsx";
import { useNavigate, useParams } from "react-router-dom";
import { readBranch } from "../../../services/branch.service.ts";
import Title from "../../Title.tsx";
import { Panel, PanelHeader } from "../../panel/panel.jsx";
import HorarioSucuralForm from "./HorarioSucuralForm.tsx";
import { useFormik } from "formik";
import Button from "../../Button.tsx";
import {
  generateTimeIntervals,
  getDateByDayOfWeek,
  shifts,
  weekDays,
  weeks,
} from "../../../utils/constanst.js";
import { ScheduleSchema } from "../../../validation/schemas/ScheduleSchema.ts";
import {
  createHistory,
  readHoursByBranch,
  saveSchedule,
} from "../../../services/branchSchedule.service.ts";
import { notification } from "../../../utils/Notifications.tsx";
import { useSelector } from "react-redux";
import { RootState } from "../../../store/index.ts";
import ModalContainer from "../../ModalContainer.tsx";
import { Tooltip } from "@mui/material";
import History from "./History.tsx";

type Props = {};

const SucursalesHorio = (props: Props) => {
  useAppSettings();
  const navigate = useNavigate();
  const { refreshScheduleFunction } = useSelector(
    (state: RootState) => state.schedule
  );
  const [firstRender, setFirstRender] = React.useState(true);
  const [loading, setLoading] = React.useState(true);
  const [branch, setBranch] = React.useState<any>(null);
  const [showModal, setShowModal] = React.useState<any>();
  const [selectedDays, setSelectedDays] = React.useState<any>([]);
  const [showHistory, setShowHistory] = React.useState<any>(false);

  let id;

  const { user } = useSelector((state: RootState) => state.auth);
  const { id: ipParams } = useParams();

  if (user.rol === "Lider de sucursal") {
    id = user.branchId;
  } else {
    id = ipParams;
  }

  React.useEffect(() => {
    const loadBranch = async () => {
      if (id) {
        const branch = await readBranch(Number(id));
        setBranch(branch);
      }
      setLoading(false);
    };

    loadBranch();
  }, []);

  const formik = useFormik({
    initialValues: {
      branchName: null,
      branchId: Number(id) || -99,
      week: -99,
      shift: -99,
      dayOfWeek: -99,
      schedule: [
        {
          position: "",
          x: 0,
          w: 0,
          originalId: 0,
          positionId: -99,
          shift: "",
          show: false,
        },
      ],
    },
    validationSchema: ScheduleSchema,
    validateOnChange: true,
    validateOnBlur: false,
    onSubmit: async (values) => {
      console.log(values.schedule);
      const branchHours = await readHoursByBranch(values.branchId);

      const hoursArray = await generateTimeIntervals(
        branchHours?.matutinoStart,
        branchHours?.vespertinoEnd
      );
      hoursArray.unshift(
        { key: -99, display: "Hora" },
        { key: -99, display: "Hora" }
      );

      try {
        if (selectedDays.length === 0) {
          console.log("values", values);
          const object = {
            branchId: values.branchId,
            week: weeks[values.week]?.value,
            shift: shifts[values.shift]?.name,
            dayOfWeek: weekDays[values.dayOfWeek]?.name,
            schedule: values.schedule
              .filter((record) => record.show)
              .map((record: any) => ({
                dateRange: weeks[values.week]?.value,
                dayOfWeek: weekDays[values.dayOfWeek].name,
                positionName: record.position,
                positionX: record.x,
                positionW: record.w,
                positionY: record.y,
                startTime: hoursArray[record.x + 2].display,
                endTime:
                  hoursArray[
                    record.x + record.w + 1 > hoursArray.length
                      ? hoursArray.length + 1
                      : record.x + record.w + 1
                  ]?.display,
                exactDate: getDateByDayOfWeek(
                  weeks[values.week]?.value,
                  weekDays[values.dayOfWeek].name
                ),
                branch: values.branchId,
                partner: record.originalId,
                position: record.positionId,
                shift: record.shift,
                positionOriginalId_: record?.positionOriginalId_,
                originalRecordId: record?.originalRecordId,
              })),
          };

          try {
            await saveSchedule({
              branchId: values.branchId,
              schedule: object.schedule,
              exactDate:
                object.schedule[0]?.exactDate ||
                getDateByDayOfWeek(
                  weeks[values.week]?.value,
                  weekDays[values.dayOfWeek]?.name
                ),
              shift: values.shift,
            });

            const timestamp = Math.floor(new Date().getTime() / 1000); // Timestamp en segundos

            // await Promise.all(
            //   object.schedule.map(async (schedule) => {
            //     await createHistory({
            //       dateRange: weeks[values.week]?.value,
            //       dayOfWeek: weekDays[values.dayOfWeek]?.name,
            //       positionName: schedule.positionName,
            //       positionX: schedule.positionX,
            //       positionW: schedule.positionW,
            //       startTime: schedule.startTime,
            //       endTime: schedule.endTime,
            //       exactDate: schedule.exactDate,
            //       branch: values.branchId,
            //       partner: schedule.partner,
            //       position: schedule.position,
            //       shift: schedule.shift,
            //       timestamp,
            //       createdAt: new Date(),
            //       createdBy: user.id,
            //     });
            //   })
            // );

            notification("Éxito", "Horario guardado correctamente", "success");
          } catch (error) {
            console.error("error", error);
            notification("error", "Error al guardar el horario", "danger");
          }
        } else {
          for (const day of selectedDays) {
            const isCurrentDay = values.dayOfWeek === day;
            const object = {
              branchId: values.branchId,
              week: weeks[values.week]?.value,
              shift: shifts[values.shift]?.name,
              dayOfWeek: weekDays[day]?.name,
              schedule: values.schedule
                .filter((record) => record.show)
                .map((record: any) => ({
                  dateRange: weeks[values.week]?.value,
                  dayOfWeek: weekDays[day].name,
                  positionName: record.position,
                  positionX: record.x,
                  positionW: record.w,
                  positionY: record.y,
                  startTime: hoursArray[record.x + 2].display,
                  endTime:
                    hoursArray[
                      record.x + record.w + 1 > hoursArray.length
                        ? hoursArray.length + 1
                        : record.x + record.w + 1
                    ]?.display,
                  exactDate: getDateByDayOfWeek(
                    weeks[values.week]?.value,
                    weekDays[day].name
                  ),
                  branch: values.branchId,
                  partner: record.originalId,
                  position: record.positionId,
                  shift: record.shift,
                  originalPositionId: record.positionId,
                  positionOriginalId_: record?.positionOriginalId_,
                  ...(isCurrentDay && {
                    originalRecordId: record?.originalRecordId,
                  }),
                })),
            };

            try {
              await saveSchedule({
                branchId: values.branchId,
                schedule: object.schedule,
                exactDate:
                  object.schedule[0]?.exactDate ||
                  getDateByDayOfWeek(
                    weeks[values.week]?.value,
                    weekDays[day]?.name
                  ),
                shift: values.shift,
              });
            } catch (error) {
              console.log("error", error);
              notification("error", "Error al guardar el horario", "danger");
            }
          }

          notification("Éxito", "Horarios guardados correctamente", "success");
          setShowModal(false);
          setSelectedDays([]);
        }
      } catch (error) {
        console.log(error);
      } finally {
        await refreshScheduleFunction();
      }
    },
  });

  const handleSave = () => {
    formik.validateForm().then(() => formik.handleSubmit());
  };

  if (showHistory) {
    return (
      <Layout loading={loading}>
        <Title
          baseTitle={`Horario de ${formik.values?.branchName ?? branch?.name}`}
          basePath=""
          title={`Horario de ${formik.values?.branchName ?? branch?.name}`}
        />
        <div className="card border-0 m-4 rounded">
          <Panel>
            <PanelHeader
              extra={
                <Tooltip title="Horario" arrow>
                  <i
                    onClick={() => setShowHistory(false)}
                    style={{ cursor: "pointer" }}
                    className="fa-solid fa-receipt"
                  ></i>
                </Tooltip>
              }
              noButton={true}
              className="bg-azul"
            >
              Historial de horarios
            </PanelHeader>
          </Panel>
          {/* <History branchId={Number(id)} /> */}
          <div className="p-2 d-flex justify-content-end w-100">
            <button
              onClick={() => setShowHistory(false)}
              className="btn btn-secondary"
            >
              Volver
            </button>
          </div>
        </div>
      </Layout>
    );
  }

  return (
    <Layout loading={loading}>
      <Title
        baseTitle={`Horario de ${formik.values?.branchName ?? branch?.name}`}
        basePath=""
        title={`Horario de ${formik.values?.branchName ?? branch?.name}`}
      />
      <div className="card border-0 m-4 rounded">
        <Panel>
          <PanelHeader
            // extra={
            //   <Tooltip title="Historial" arrow>
            //     <i
            //       onClick={() => setShowHistory(true)}
            //       style={{ cursor: "pointer" }}
            //       className="fa-solid fa-receipt"></i>
            //   </Tooltip>
            // }
            noButton={true}
            className="bg-azul"
          >
            Horario de la sucursalasdasdasdasdsad
          </PanelHeader>
          <HorarioSucuralForm
            formik={formik}
            firstRender={firstRender}
            setFirstRender={setFirstRender}
          />
          {!firstRender &&
            (formik.errors.branchId ||
              formik.errors.dayOfWeek ||
              formik.errors.schedule ||
              formik.errors.shift ||
              formik.errors.week) && (
              <div className="alert alert-danger w-50 mx-auto">
                {Object.keys(formik.errors).map((key) => (
                  <div key={key}>{formik.errors[key]}</div>
                ))}
              </div>
            )}
        </Panel>
      </div>
      <div className="card border-0 m-4 rounded p-3">
        <div className="row justify-content-end">
          <div className="col-md-3">
            <Button
              onClick={() => navigate("/sucursales")}
              title="Cancelar"
              variant="secondary"
              type="button"
            />
          </div>
          <div className="col-md-3">
            <Button
              onClick={() => setShowModal(true)}
              title="Guardar para múltiples días"
              variant="azul"
              type="submit"
            />
          </div>
          <div className="col-md-4">
            <Button
              onClick={handleSave}
              title="Guardar para día seleccionado"
              variant="azul"
              type="submit"
              disabled={formik.isSubmitting}
            />
          </div>
        </div>
      </div>
      <ModalContainer
        open={showModal}
        title="Horario de la sucursal"
        closeFn={() => setShowModal(false)}
      >
        <div></div>
        <h5 className="mb-3">Se repite el:</h5>
        <div className="d-flex justify-content-around my-3">
          {weekDays.map((day, index) => (
            <button
              key={index}
              type="button"
              onClick={() => {
                const isSelected = selectedDays.includes(index);
                setSelectedDays(
                  isSelected
                    ? selectedDays.filter((dayIndex) => dayIndex !== index)
                    : [...selectedDays, index]
                );
              }}
              className={`btn ${
                selectedDays.includes(index)
                  ? "btn-primary"
                  : "btn-outline-secondary"
              } rounded-pill mx-1`}
              title={day.name}
              style={{
                width: "40px",
                height: "40px",
                textTransform: "capitalize",
              }}
            >
              {day.name.slice(0, 1)}
            </button>
          ))}
        </div>
        <div className="d-flex flex-row mt-5">
          <button
            className="btn btn-secondary ms-auto me-2"
            onClick={() => setShowModal(false)}
          >
            Cancelar
          </button>
          <button
            className="btn btn-primary"
            onClick={() => formik.submitForm()}
          >
            Guardar
          </button>
        </div>
      </ModalContainer>
    </Layout>
  );
};

export default SucursalesHorio;

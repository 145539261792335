import React, { useEffect, useState } from "react";
import { Partner } from "../../models/Partner.ts";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import "bootstrap/dist/css/bootstrap.min.css";
import {
  fetchWildcardHistory,
  fetchWildcardCodes,
  fetchAssistanceByCode,
  fetchRemainingWildcardsPartner,
} from "../../services/assistenceCode.ts";
import ModalContainer from "../ModalContainer.tsx";
import ModalCodeAssitence from "../catalogo/Sucursales/componentes/ModalCodeAssitence.tsx";

import { Tooltip } from "@mui/material";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import ModalUpdateWildcards from "../catalogo/Sucursales/componentes/ModalUpdateWildcards.tsx";
import ModalCheckDetails from "../catalogo/Sucursales/componentes/ModalCheckDetails.tsx";
import EmptyImage from "../../assets/img/ic_content.svg";
import useFetchCheckDetails from "./hooks/useFetchCheckDetails.tsx";
import S3Service from "../../services/s3.service.ts";
import { formatDate } from "../../utils/index.ts";

const CodeAssistence = ({
  partner,
  reloadPage,
}: {
  partner: Partner;
  reloadPage: any;
}) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [history, setHistory] = useState<any[]>([]);
  const [codes, setCodes] = useState<any[]>([]);
  const [open, setOpen] = useState(false);
  const [value, setValue] = useState(0);
  const [openAddCode, setOpenAddCode] = useState(false);
  const [openUpdateWildcards, setOpenUpdateWildcards] = useState(false);
  const [remainingWildcards, setRemainingWildcards] = useState<number>(0);
  const [showCheckPhoto, setShowCheckPhoto] = useState(false);
  const [selectedPhotoUrl, setSelectedPhotoUrl] = useState<string>("");
  const {
    checkDetails,
    openCheckDetails,
    setOpenCheckDetails,
    loading: loadingCheckDetails,
    error,
    fetchCheckDetails,
  } = useFetchCheckDetails();
  const fetchHistory = async () => {
    if (loading) return;
    setLoading(true);
    try {
      const response = await fetchWildcardHistory(partner.id);
      setHistory(response.data);

      const remainingWildcards = await fetchRemainingWildcardsPartner(
        partner.id
      );
      setRemainingWildcards(remainingWildcards.data);
    } catch (error) {
      console.error("Error al cargar historial de comodines", error);
    }
    setLoading(false);
  };

  const fetchCodes = async () => {
    if (loading) return;
    setLoading(true);
    try {
      const response = await fetchWildcardCodes(partner.id);
      setCodes(response.data.checks);
    } catch (error) {
      console.error("Error al cargar códigos de comodines", error);
    }
    setLoading(false);
  };

  const historylength = history.length;

  useEffect(() => {
    if (!partner || loading) return;
    fetchHistory();
    fetchCodes();
    fetchProfileImage();
  }, [open, openUpdateWildcards, value, history]);

  const getColor = (status: string) => {
    switch (status) {
      case "Codigo Generado":
        return "bg-success p-2 rounded-4 text-white fw-600";
      case "Iniciales":
        return "bg-info p-2 rounded-4 text-white fw-600";
      case "Agregado":
        return "bg-warning p-2 rounded-4 text-white fw-600";
      default:
        return "bg-secondary text-white fw-600";
    }
  };

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  const [profileImage, setProfileImage] = React.useState(
    "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png"
  );
  const fetchProfileImage = async () => {
    try {
      const profileUrl = await S3Service.getFile("PERFIL_" + partner.person.curp);
      setProfileImage(profileUrl.url);
      console.log("PROFILE URL",profileUrl.url)
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className="tab-pane fade" id="codigos">
      <div className="card border-0 m-rounded p-3">
        <div className="row g-4 mb-1 d-flex align-items-center">
          <div className="col-12 d-flex justify-content-between align-items-center">
            <Tabs
              value={value}
              onChange={handleTabChange}
              aria-label="Tabs"
              className="flex-grow-1"
            >
              <Tab label="Códigos activos" />
            </Tabs>

            <div className="d-flex align-items-center">
              <span
                className={`badge p-2 me-2 ${
                  remainingWildcards <= 11 ? "text-danger" : "text-primary"
                }`}
                style={{ fontSize: "1rem" }}
              >
                Total de comodines: {remainingWildcards || 0}
              </span>
              <button
                className="btn btn-warning p-2 me-2"
                onClick={() => setOpenUpdateWildcards(true)}
              >
                <i className="fas fa-plus"></i> Comodín
              </button>
            </div>
          </div>
        </div>
        <Box role="tabpanel" hidden={value !== 0}>
          <div className="mt-1">
            <div className="table-sticky mb-1">
              <table className="table table-hover table-panel text-nowrap align-middle mb-0">
                <thead className="sticky-thead table-light text-center">
                  <tr>
                    <th className="align-middle">ID</th>
                    <th className="align-middle">Fecha de chequeo</th>
                    <th className="align-middle">Foto de regsitro</th>
                    <th className="align-middle">Foto del colaborador</th>
                    <th className="align-middle">Veces usadas</th>
                    <th className="align-middle">Acciones</th>
                  </tr>
                </thead>
                <tbody>
                  {codes.length > 0 ? (
                    codes.map((code, index) => (
                      <tr className="text-center" key={index}>
                        <td className="fw-bold">{code?.id}</td>
                        <td>{formatDate(code?.checkDate)}</td>
                        <td>
                          <img
                            src={code?.photoUrl}
                            alt="Code Image"
                            className="rounded-3"
                            style={{
                              width: "50px",
                              height: "50px",
                              objectFit: "cover",
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              setSelectedPhotoUrl(code?.photoUrl);
                              setShowCheckPhoto(true);
                            }}
                          />
                        </td>
                        <td>
                          <img
                            src={profileImage}
                            alt="Profile Image"
                            className="rounded-3"
                            style={{
                              width: "50px",
                              height: "50px",
                              objectFit: "cover",
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              setSelectedPhotoUrl(profileImage);
                              setShowCheckPhoto(true);
                            }}
                          />
                        </td>
                        <td>{code?.indexAssis}</td>
                        <td>
                          <Tooltip title="Detalles del registro">
                            <button
                              className="btn btn-info"
                              onClick={() => fetchCheckDetails(code?.id)}
                              disabled={code?.usageCount === 0}
                            >
                              <i className="fas fa-eye"></i>
                            </button>
                          </Tooltip>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan={5}>
                        <div className="py-4">
                          <div className="d-flex">
                            <img
                              src={EmptyImage}
                              alt=""
                              className="mx-auto w-250px"
                            />
                          </div>
                          <h5 className="text-secondary text-center fs-20px">
                            No existen registros aún
                          </h5>
                        </div>
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </Box>
        <ModalContainer
          open={openUpdateWildcards}
          title="Actualizar cantidad de comodines"
          closeFn={() => setOpenUpdateWildcards(false)}
          children={
            <ModalUpdateWildcards
              partnerId={partner.id}
              setShowModal={setOpenUpdateWildcards}
              reloadPage={reloadPage}
              remainingWildcards={remainingWildcards}
            />
          }
        />
        <ModalContainer
          open={open}
          title="Crear nuevo código de asistencia"
          closeFn={() => setOpen(false)}
          children={
            <ModalCodeAssitence
              partnerId={partner.id}
              setShowModal={(e) => setOpen(e)}
            />
          }
        />
        <ModalContainer
          open={openCheckDetails}
          title="Historial de chequeos"
          closeFn={() => setOpenCheckDetails(false)}
          children={
            <ModalCheckDetails
              partnerId={partner.id}
              setShowModal={(e) => setOpenCheckDetails(e)}
              checkDetails={checkDetails}
            />
          }
        />
        <ModalContainer
          open={showCheckPhoto}
          title="Previsualización de foto"
          closeFn={() => setShowCheckPhoto(false)}
        >
          <img
            src={selectedPhotoUrl}
            alt="Previsualización de foto"
            style={{ width: "100%" }}
          />
        </ModalContainer>
      </div>
    </div>
  );
};

export default CodeAssistence;

import React, { useEffect, useRef } from "react";
import CustomSelectField from "../../formik/CustomSelectField.tsx";
import { PanelBody } from "../../panel/panel.jsx";
import SheduleComponent from "./SheduleComponent.tsx";
import {
  readHoursByBranch,
  readPositionsByBranchAndShift,
  readSchedulesByBranchShiftWeekDay,
} from "../../../services/branchSchedule.service.ts";
import { shifts, weekDays, weeks } from "../../../utils/constanst.js";
import FullScreenContainer from "../../FullScreenContainer.tsx";
import FullScreenSchedule from "./FullScreenSchedule.tsx";
import Loader from "../../Loader.tsx";
import moment from "moment";
import { readBranches } from "../../../services/branch.service.ts";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../store/index.ts";
import { setRefreshScheduleFunction } from "../../../store/slices/scheduleSlice.ts";

const HorarioSucuralForm = ({ formik, firstRender, setFirstRender }) => {
  const [loading, setLoading] = React.useState(false);
  const [positionsArray, setPositionsArray] = React.useState<any>([]);
  const [existingsSchedules, setExistingsSchedules] = React.useState<any>([]);
  const [positionsSorted, setPositionsSorted] = React.useState<any>([]);
  const [fullScreen, setFullScreen] = React.useState(false);
  const [hours, setHours] = React.useState<any>([]);
  const [branches, setBranches] = React.useState<any>([]);
  const dispatch = useDispatch();

  const previousWeekday = useRef(null);
  const { user } = useSelector((state: RootState) => state.auth);

  const loadDependeicies = async (index?) => {
    try {
      setLoading(true);
      if (user.rol === "Administrador" || user.rol === 'Líder de líderes' || user.rol === "Administrador-") {
        const branches = await readBranches();
        setBranches(branches);
      }
      const values = formik.values;
      const shift =
        shifts.find((shift) => shift.id === values.shift)?.name ?? "";
      if (positionsArray.length === 0) {
      const positions = await readPositionsByBranchAndShift(
        values.branchId,
        shift
      );

      const positionsArray = positions.map((position) => {
        const totalWorkersRequired = position.numberOfWorkers;
        const workers: any = [];

        for (let i = 0; i < totalWorkersRequired; i++) {
          workers.push({
            id: i,
            positionId: position.position.id,
            positionName: position.position.name + " " + (i + 1),
            originalPositionName: position.position.name,
            department:
              position.position?.department?.name || "Sin Departamento",
            shift: position.shift,
            positionOrder: position.position.order,
            positionisDeleted: position.position.isDeleted,
            positionOriginalId_: position.position.id,
            positionShowSchedule: position.showSchedule,
          });
        }
        return workers;
      });
      setPositionsArray(positionsArray.flat());

      const shiftOrder = ["Matutino", "Intermedio", "Vespertino"];

      const sorted = positionsArray
        .flat()
        .filter((position) => position.positionShowSchedule)
        .sort((a, b) => {
          const shiftComparison =
            shiftOrder.indexOf(a.shift) - shiftOrder.indexOf(b.shift);
          if (shiftComparison !== 0) return shiftComparison;

          const positionOrderComparison = a.positionOrder - b.positionOrder;
          if (positionOrderComparison !== 0) return positionOrderComparison;

          const departmentComparison = a.department.localeCompare(b.department);
          if (departmentComparison !== 0) return departmentComparison;

          return a.positionName.localeCompare(b.positionName);
        });

      console.log("sorted: ", sorted);

      const resultArray: any = [];
      let currentShift = "";
      let currentDepartment = "";

      sorted.forEach((position) => {
        if (position.shift !== currentShift) {
          currentShift = position.shift;
          currentDepartment = "";
          resultArray.push({ title: currentShift, shift: currentShift });
        }

        if (position.department !== currentDepartment) {
          currentDepartment = position.department;
        }

        resultArray.push(position);
      });

      setPositionsSorted(resultArray);

      if (
        values.week === -99 ||
        values.shift === -99 ||
        values.dayOfWeek === -99
      ) {
        return;
      }
      }

      setFirstRender(false);

      const schedules = await readSchedulesByBranchShiftWeekDay(
        values.branchId,
        shift,
        weeks[values.week]?.value,
        weekDays[index !== undefined ? index : values.dayOfWeek]?.name
      );

      setExistingsSchedules(schedules);

      const branchHours = await readHoursByBranch(values.branchId);
      setHours(branchHours);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const autoSave = async (index) => {
    setLoading(true);

    const currentWeekday = index;
    const weekdayToSave = Number(previousWeekday.current);

    formik.setFieldValue("dayOfWeek", weekdayToSave);

    try {
      await formik.submitForm();

      formik.setFieldValue("dayOfWeek", currentWeekday);

      await loadDependeicies(index);
    } catch (error) {
      console.error("Error during submit:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!firstRender) return;
    loadDependeicies().then(() => {
      setLoading(false);
    });
    dispatch(setRefreshScheduleFunction(async () => await loadDependeicies()));
  }, [formik.values.week, formik.values.shift, formik.values.dayOfWeek]);

  useEffect(() => {
    loadDependeicies().then(() => {
      setLoading(false);
    });
  }, [formik.values.week, formik.values.shift, formik.values.branchId]);

  const setTodaySchedule = () => {
    const today = moment().day();
    const adjustedToday = today === 0 ? 6 : today - 1;
    const day = weekDays.find((day) => day.id === adjustedToday);
    const currentWeek = weeks[1].id;
    const allShift = shifts[3].id;
    const dayOfWeek = day?.id;
    formik.setFieldValue("week", currentWeek);
    formik.setFieldValue("shift", allShift);
    formik.setFieldValue("dayOfWeek", dayOfWeek);
  };

  // if (loading) {
  //   return <Loader isLoading={loading} />;
  // }

  return (
    <PanelBody>
      <Loader isLoading={loading} />
      <div className={`row mb-4 fs-13px col-md-12`}>
        <label className={`form-label col-form-label col-md-3 text-end`} />
        <div className={`col-md-9`} role="group">
          <button
            type="button"
            className="btn btn-primary"
            onClick={() => setTodaySchedule()}
          >
            Hoy
          </button>
        </div>
      </div>
      {["Administrador", "Administrador-"].includes(user.rol) && (
        <CustomSelectField
          formik={formik}
          field="branchId"
          label="Sucursal"
          list={branches}
          fieldName="branchName"
          sm={3}
        />
      )}

      <div className="form-group">
        <CustomSelectField
          formik={formik}
          field="week"
          label="Semana"
          list={weeks}
          sm={3}
        />
      </div>

      <div className={`row mb-4 fs-13px col-md-12`}>
        <label
          className={`form-label col-form-label col-md-3 text-end`}
          style={{
            color:
              formik.errors.shift && formik.touched.shift ? "red" : "inherit",
          }}
        >
          Turno *
        </label>
        <div className={`col-md-9`} role="group">
          <div className="btn-group">
            {shifts.map((shift) => (
              <button
                type="button"
                key={shift.id}
                className={`btn ${
                  formik.values.shift === shift.id
                    ? "btn-primary"
                    : "btn-outline-secondary"
                } 
                    ${
                      formik.errors.shift && formik.touched.shift
                        ? "btn-outline-danger"
                        : ""
                    }`}
                onClick={() => formik.setFieldValue("shift", shift.id)}
              >
                {shift.name}
              </button>
            ))}
          </div>
          {formik.errors.shift && formik.touched.shift && (
            <div className="invalid-feedback d-block">
              {formik.errors.shift}
            </div>
          )}
        </div>
      </div>

      <div className={`row mb-4 fs-13px col-md-12`}>
        <label
          className={`form-label col-form-label col-md-3 text-end`}
          style={{
            color:
              formik.errors.shift && formik.touched.shift ? "red" : "inherit",
          }}
        >
          Día de la semana *
        </label>
        <div className={`col-md-9`} role="group">
          <div className="btn-group" role="group">
            {weekDays.map((day, index) => (
              <button
                type="button"
                key={index}
                className={`btn ${
                  formik.values.dayOfWeek === index
                    ? "btn-primary"
                    : "btn-outline-secondary"
                } 
                ${
                  formik.errors.dayOfWeek && formik.touched.dayOfWeek
                    ? "btn-outline-danger"
                    : ""
                }`}
                onClick={() => {
                  previousWeekday.current = formik.values.dayOfWeek;
                  formik.setFieldValue("dayOfWeek", index);
                  if (!firstRender) autoSave(index);
                }}
              >
                {day.name}
              </button>
            ))}
          </div>
          {formik.errors.dayOfWeek && formik.touched.dayOfWeek && (
            <div className="invalid-feedback d-block">
              {formik.errors.dayOfWeek}
            </div>
          )}
        </div>
      </div>

      {fullScreen && (
        <FullScreenContainer
          open={fullScreen}
          tittle="Horario de la sucursal"
          onClsFn={() => setFullScreen(false)}
        >
          <FullScreenSchedule
            existingsSchedules={existingsSchedules}
            positionsArray={
              formik.values.shift === 3 ? positionsSorted : positionsArray
            }
            formik={formik}
            turno={formik.values.shift}
            setPositionsArray={setPositionsArray}
            selectedDay={formik.values.dayOfWeek}
          />
        </FullScreenContainer>
      )}
      {formik.values.shift !== -99 &&
        formik.values.dayOfWeek !== -99 &&
        (hours?.id ? (
          positionsArray && positionsArray.length > 0 ? (
            <SheduleComponent
              selectedWeek={formik.values.week}
              hours={hours}
              existingsSchedules={existingsSchedules}
              positionsArray={
                formik.values.shift === 3 ? positionsSorted : positionsArray
              }
              formik={formik}
              turno={formik.values.shift}
              setPositionsArray={setPositionsArray}
              selectedDay={formik.values.dayOfWeek}
            />
          ) : (
            <p className="text-center mt-5">
              No hay puestos disponibles para el turno seleccionado
            </p>
          )
        ) : (
          <div className="row d-flex justify-content-between w-50 mx-auto mt-5">
            <div className="alert alert-warning" role="alert">
              <h4 className="alert-heading">¡Atención!</h4>
              <p>La sucursal no cuenta con un horario establecido.</p>
              <hr />
              <p className="mb-0">
                Por favor, completa el horario de la sucursal para poder asignar
                los trabajadores.
              </p>
            </div>
          </div>
        ))}
    </PanelBody>
  );
};

export default HorarioSucuralForm;

/*
  Cambio tabs clear layout
  Verde el rango horario
  pantalla completa
*/

import React from "react";
import { Partner } from "../../models/Partner";
import { toCamelCase } from "../../hooks/toCameCase.tsx";
import { useSelector } from "react-redux";
import { RootState } from "../../store/index.ts";
import ModalContainer from "../ModalContainer.tsx";

const ProfileHeader = ({
  partner,
  profileUrl,
}: {
  partner: Partner;
  profileUrl: string;
}) => {
  const highRisk = Boolean(partner?.candidate?.vacancy?.position?.highRisk);
  const { user } = useSelector((state: RootState) => state.auth);
  const [showProfileImage, setShowProfileImage] = React.useState(false);

  const [loading, setLoading] = React.useState(true);
  const [error, setError] = React.useState(false);

  const handleImageLoad = () => {
    setLoading(false);
  };

  const handleImageError = () => {
    setLoading(false);
    setError(true);
  };

  const getImage = () => {
    if(loading) return '/assets/img/loading.gif';
    if(error) return '/assets/img/avatar.jpg';

    return profileUrl;
  }

  return (
    <div className="profile ">
      <div className="profile-header">
        <div className="profile-header-cover" />
        <div className="profile-header-content">
          <div
            onClick={() => setShowProfileImage(true)}
            style={{ cursor: "pointer" }}
            className="profile-header-img"
          >
            <img src={getImage()} alt="" onLoad={handleImageLoad}  onError={handleImageError} />
          </div>

          <div className="profile-header-info">
            <h4 className="mt-0 mb-1">
              {toCamelCase(
                `${partner.person.firstName} ${partner.person.lastName} ${partner.person.secondLastName}`
              )}
            </h4>
            <p className="mb-2">
              Puesto: {partner?.candidate?.vacancy?.position?.name} en{" "}
              {partner?.candidate?.branch?.name}
            </p>
            <a
              href="#"
              className={`btn btn-xs btn-${highRisk ? "red" : "green"}`}
            >
              {highRisk ? "Alto riesgo" : "No es de alto riesgo"}
            </a>
          </div>
        </div>
        <ul className="profile-header-tab nav nav-tabs">
          <li className="nav-item">
            <a
              href="#informacion"
              className="nav-link active"
              data-bs-toggle="tab"
            >
              Datos generales
            </a>
          </li>
          <li className="nav-item">
            <a href="#expediente" className="nav-link" data-bs-toggle="tab">
              Expediente
            </a>
          </li>
          {(user.rol === "Trabajador" || user.rol === "Administrador" || user.rol === "Administrador-") && (
            <li className="nav-item">
              <a href="#contrato" className="nav-link" data-bs-toggle="tab">
                Contratos
              </a>
            </li>
          )}
          {(user.rol === "Contador" || user.rol === "Administrador" || user.rol === "Administrador-") && (
            <li className="nav-item">
              <a href="#contratoIMSS" className="nav-link" data-bs-toggle="tab">
                Contrato IMMS
              </a>
            </li>
          )}
          {/* Corregir */}
          {/* {(user.rol === "Trabajador" || user.rol === "Administrador") && (
            <li className="nav-item">
              <a href="#horario" className="nav-link" data-bs-toggle="tab">
                Horario
              </a>
            </li>
          )} */}

          <li className="nav-item">
            <a href="#empleo" className="nav-link" data-bs-toggle="tab">
              Datos del empleo
            </a>
          </li>

          {(user.rol === "Reclutamiento" || user.rol === "Administrador" || user.rol === "Trabajador" || user.rol === "Administrador-") && (
            <li className="nav-item">
            <a href="#insignias" className="nav-link" data-bs-toggle="tab">
              Insignias
            </a>
           </li>
          )}
          {(user.rol === "Trabajador" || user.rol === "Administrador") && (
            <li className="nav-item">
              <a href="#incapacidades" className="nav-link" data-bs-toggle="tab">
                Incapacidades
              </a>
            </li>
          )}
          {(user.rol === "Trabajador" || user.rol === "Administrador" || user.rol === "Administrador-") && (
            <li className="nav-item">
              <a href="#codigos" className="nav-link" data-bs-toggle="tab">
                Códigos
              </a>
            </li>
          )}
        
        </ul>
      </div>
      <ModalContainer
        open={showProfileImage}
        title="Foto de perfil"
        closeFn={() => setShowProfileImage(false)}
      >
        <img src={getImage()} onLoad={handleImageLoad}  onError={handleImageError} alt="Foto de perfil" style={{ width: "100%" }} />
      </ModalContainer>
    </div>
  );
};

export default ProfileHeader;

import React from "react";
import * as moment from "moment-timezone";
import { OperationalUrgency } from "../../../../models/operationalUrgency";
import ButtonLink from "../../../ButtonLink.tsx";
import TableOperationalUrgencyPartners from "./TableOperationalUrgencyPartners.tsx";

const OperationalUrgencyRow = ({
  item,
  no,
}: {
  item: OperationalUrgency;
  no: number;
}) => {
  const [minutesWithoutReplacement, setMinutesWithoutReplacement] =
    React.useState<number>(0);
  const [minutesWithReplacement, setMinutesWithReplacement] =
    React.useState<number>(0);
  const [percentageCovered, setPercentageCovered] = React.useState<number>(0);
  const [allHasReplacement, setAllHasReplacement] =
    React.useState<boolean>(true);

  const collapseId = `collapse${no}`;
  const [isOpen, setIsOpen] = React.useState(false);

  const toggleAccordion = () => setIsOpen(!isOpen);
  const headingId = `heading${no}`;

  const total = item.lateArrivals.length;

  const recordsWithoutReplacement = item.lateArrivals.filter(
    (record) => !record.replacement
  );
  const recordsWithReplacement = item.lateArrivals.filter(
    (record) => record.replacement
  );

  const currentTime = moment().tz("America/Mexico_City").format("HH:mm");

  // Minutos sin asignar
  React.useEffect(() => {
    const totalMinutesWithoutReplacement = item.lateArrivals.reduce(
      (acc, record) => {
        const startTime = moment(record.startTime.split(" - ").at(0), "HH:mm");

        if (record?.replacement?.createdAt) {
          const replacementCreate = moment(record.replacement.createdAt)
            .tz("America/Mexico_City")
            .format("HH:mm");

          const replacementCreateMoment = moment(replacementCreate, "HH:mm");

          const diff = replacementCreateMoment.diff(startTime, "minutes");

          return acc + diff;
        }

        const diff = moment(currentTime, "HH:mm").diff(startTime, "minutes");

        return acc + diff;
      },
      0
    );
    setMinutesWithoutReplacement(totalMinutesWithoutReplacement);
  }, [item]);

  // Tiempo sin que llegue el reemplazo
  React.useEffect(() => {
    const totalMinutesWithReplacement = recordsWithReplacement.reduce(
      (acc, record) => {
        // Verificar si ya checo y comparalo con ese tiempo

        const checkTime = record?.replacement.assistanceCheck;
        const replacementCreate = moment(record.replacement.createdAt)
          .tz("America/Mexico_City")
          .format("HH:mm");

        if (checkTime) {
          // Comparar el tiempo de creacion con el de su entrada
          const replacementTime = moment(replacementCreate, "HH:mm");
          const checkTimeMoment = moment(checkTime.checkTime, "HH:mm:ss");

          const differenceInMinutes = checkTimeMoment.diff(
            replacementTime,
            "minutes"
          );

          return acc + differenceInMinutes;
        }

        const replacementCreateMoment = moment(replacementCreate, "HH:mm");
        const currentTimeMoment = moment(currentTime, "HH:mm");

        const diff = currentTimeMoment.diff(replacementCreateMoment, "minutes");

        return acc + diff;
      },
      0
    );
    setMinutesWithReplacement(totalMinutesWithReplacement);
  }, [item]);

  // Porcentaje de las faltas que ya estan cubiertas
  React.useEffect(() => {
    const totalWithReplacement = recordsWithReplacement.length;

    // console.log({ totalWithReplacement, total });

    const percentage = (totalWithReplacement / total) * 100;

    setPercentageCovered(percentage);
  }, [item]);

  // Verificar si todos los registros tienen reemplazo
  React.useEffect(() => {
    const allHasReplacement = item.lateArrivals.every(
      (record) => record?.replacement
    );

    setAllHasReplacement(allHasReplacement);
  }, [item]);

  return (
    <React.Fragment key={no}>
      <tr style={{ textAlign: "center" }}>
        <td
          className={`accordion-button ${isOpen ? "" : "collapsed"}`}
          data-bs-toggle="collapse"
          data-bs-target={`#${collapseId}`}
          aria-expanded={`${isOpen ? "true" : "false"}`}
          aria-controls={collapseId}
          onClick={toggleAccordion}
          style={{
            paddingTop: "3.7%",
            paddingBottom: "3.7%",
            cursor: "pointer",
            backgroundColor: isOpen ? "none" : "#dee2e6",
          }}
        >
          <h4>{item.branch.name}</h4>
        </td>
        <td style={{ border: "none" }}>
          <h5>{total}</h5>
        </td>
        <td style={{ border: "none" }}>
          <ButtonLink
            className={`btn btn-${
              allHasReplacement ? "green" : "red"
            } d-block btn-lg fs-14px`}
            text={minutesWithoutReplacement}
            type="button"
          />
          {/* <h5>{minutesWithoutReplacement}</h5> */}
        </td>
        <td style={{ border: "none" }}>
          <h5>{minutesWithReplacement}</h5>
        </td>
        <td style={{ border: "none" }}>
          <h5>{minutesWithoutReplacement + minutesWithReplacement}</h5>
        </td>
        <td style={{ border: "none" }}>
          <h5>
            {isNaN(Number(percentageCovered.toFixed(0)))
              ? "100"
              : percentageCovered.toFixed(0)}
            %
          </h5>
        </td>
      </tr>

      {isOpen && (
        <tr>
          <td colSpan={6} style={{ padding: "0" }}>
            <div className="accordion-collapse p-3">
              <TableOperationalUrgencyPartners records={item} />
            </div>
          </td>
        </tr>
      )}
    </React.Fragment>
  );
};

export default OperationalUrgencyRow;

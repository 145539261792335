import React from "react";
import Loader from "../Loader.tsx";
import { Partner } from "../../models/Partner.ts";
import InputEditProfile from "../formik/InputEditProfile.tsx";
import { useFormik } from "formik";
import { FillPreviewInfo } from "../../interfaces/formik/IEditUser.ts";
import { useSelector } from "react-redux";
import { RootState } from "../../store/index.ts";
import ApplicationList from "../history/ApplicationList.tsx";

import {
  getChangePositionByPartner,
  readApplicationsByCurp,
} from "../../services/application.service.ts";
import { useNavigate } from "react-router-dom";
import { notification } from "../../utils/Notifications.tsx";
import {
  getInfoPositionPartner,
  getSalaryHistory,
  updateSalary,
} from "../../services/partner.service.ts";
import CustomCheckboxField from "../formik/CustomCheckboxField.tsx";
import { getShifts } from "../../services/shift.service.ts";
import CustomSelectField from "../formik/CustomSelectField.tsx";
import SelectEditProfile from "../formik/SelectEditProfile.tsx";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import ApplicationListReinstatements from "../history/ApplicationListReinstatements.tsx";
import ApplicationListChangePosition from "../history/ApplicationListChangePosition.tsx";
import ApplicationListChangeBranch from "../history/ApplicationListChangeBranch.tsx";
import ModalContainer from "../ModalContainer.tsx";
import EditTimeAppoinment from "../config/EditTimeAppoinment.tsx";
import UpdateSalary from "./UpdateSalary.tsx";
import { Tooltip } from "@mui/material";
import ApplicationListChangeSalary from "../history/ApplicationListChangeSalary.tsx";
import { createFreeDay, fetchFreeDaysByDateRangeAndPartnerId } from "../../services/freeDay.service.ts";
import { weekDays, weeks } from "../../utils/constanst.js";
const InformationPosition = ({
  partner,
  reloadPage,
}: {
  partner: Partner;
  reloadPage: () => Promise<void>;
}) => {
  const navigate = useNavigate();
  const [loading, setLoading] = React.useState(false);
  const [modalSalary, setModalSalary] = React.useState(false);
  const [showModal, setShowModal] = React.useState(false);
  const [selectedDays, setSelectedDays] = React.useState<number[]>([]);

  const formik = useFormik({
    enableReinitialize: true,

    initialValues: FillPreviewInfo(partner),
    onSubmit: async (values) => {
      const {
        monthlySalaryIMSS,
        monthlySalary,
        banDate,
        recruitmentDate,
        shiftManager,
        shift,
      } = values;
      console.log({shiftManager})
      setLoading(true);
      try {
        const response = await updateSalary(partner.id, {
          salary: Number(monthlySalary),
          salaryIMSS: Number(monthlySalaryIMSS),
          banDate,
          recruitmentDate,
          shift,
          shiftManager,
          ...(values.originalRecruitmentDateId != -1 && {
            recruitmentDateId: values.originalRecruitmentDateId,
            originalRecruitmentDate: values.originalRecruitmentDate,
          }),
        });
        notification("", "Datos actualizados", "success");

        await reloadPage();
        await fetchApplications();
      } catch (error) {
        notification(
          "Error",
          "Ocurrió un error al actualizar el salario",
          "danger"
        );
      } finally {
        setLoading(false);
      }
    },
  });
  const { user } = useSelector((state: RootState) => state.auth);

  const curp = partner.person.curp;
  const [applications, setApplications] = React.useState<any[]>([]);
  const [shifts, setShifts] = React.useState<any[]>([]);
  const [changeBranch, setChangeBranch] = React.useState<any[]>([]);
  const [changeSalary, setChangeSalary] = React.useState<any[]>([]);

  const fetchApplications = async () => {
    if (!curp) return;
    try {
      const response = await readApplicationsByCurp(curp);
      setApplications(response);

      const shifts = await getShifts();
      setShifts(shifts);

      const changeBranches = await getChangePositionByPartner(partner.id);
      setChangeBranch(changeBranches);

      const changeSalaries = await getSalaryHistory(partner.id);
      setChangeSalary(changeSalaries);

      const freeDays = await fetchFreeDaysByDateRangeAndPartnerId(weeks[1]?.value, partner?.id);

      if (!weeks[1] || !partner?.id) {
        return
      }

      formik.setFieldValue("week", weeks[1]?.id);

      if (freeDays?.days && Array.isArray(freeDays.days)) {
        const selectedDays = freeDays.days.map((day) => {
          const index = weekDays.findIndex((d) => d.name === day);

          if (index === -1) {
            throw new Error(`Día no válido: ${day}`);
          }

          return index;
        });

        setSelectedDays(selectedDays);
      } else {
        return
      }



    } catch (error) {
      setApplications([]);
    }
  };

  const [salary, setSalary] = React.useState<any>({});

  const fetchSalary = async () => {
    try {
      console.log("partner.id", partner.id);
      const responseSalary = await getInfoPositionPartner(partner.id);
      console.log("datos para usar en salario", responseSalary);
      setSalary(responseSalary);
    } catch (error) {
      console.log(error);
    }
  };

  React.useEffect(() => {
    fetchApplications();
    fetchSalary();
  }, [curp, partner.id]);

  interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
  }

  function CustomTabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
      </div>
    );
  }
  function a11yProps(index: number) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const updateFreeDays = async () => {
    const { week } = formik.values;
    const days = selectedDays.map((day) => weekDays[day].id);
    try {
      const object = {
        partnerId: partner.id,
        days: weekDays.filter((day) => days.includes(day.id)).map((d) => d.name),
        dateRange: weeks.find((w) => w.id === week)?.value,
      };

      await createFreeDay(object);

      await fetchApplications();
      notification("Guardado", "Días libres actualizados", "success");
    } catch (error) {
      notification("Error", "Ocurrió un error al actualizar los días libres", "danger");
    }finally {
      setShowModal(false);
    }
  }

  const restrictedRoles = ["Administrador", "Administrador-"];
  const unclickable = !restrictedRoles.includes(user?.rol);

  return (
    <div className="tab-pane fade" id="empleo">
      <div className="table-responsive form-inline">
        <table className="table table-profile align-middle">
          <thead>
            <tr>
              <th />
              <th>
                <h4>
                  <small>Puesto: </small>
                  {partner.candidate.vacancy.position.name}
                </h4>
              </th>
            </tr>
          </thead>
          <tbody className="overflow-x-hidden">
            <tr className="highlight">
              <td colSpan={2} />
            </tr>
            <InputEditProfile
              formik={formik}
              field="originalRecruitmentDate"
              label="Fecha de reclutamiento"
              placeholder="Fecha de reclutamiento"
              sm={3}
              type="date"
              unclickable={unclickable}
            />
            <InputEditProfile
              formik={formik}
              field="recruitmentDate"
              label="Fecha de cambio de posición"
              placeholder="Fecha de cambio de posición"
              sm={3}
              type="date"
              unclickable={unclickable}
            />

            {user.rol === "Administrador" && (
              <InputEditProfile
                formik={formik}
                field="monthlySalaryString"
                label="Salario"
                placeholder="Salario"
                sm={3}
                disabled
                button={
                  <Tooltip title={`Modificar Salario`} arrow>
                    <button
                      type="submit"
                      className="btn btn-success "
                      onClick={() => setModalSalary(true)}
                    >
                      <span className="d-none d-sm-inline"></span>
                      <i className="fa-solid fa-pen-to-square"></i>
                    </button>
                  </Tooltip>
                }
              />
            )}

            {user.rol === "Contador" && (
              <InputEditProfile
                formik={formik}
                field="monthlySalaryIMSS"
                label="Salario IMSS"
                placeholder="Salario IMSS"
                sm={3}
                type="number"
              />
            )}
            <tr>
              <td className="field">Turno: </td>
              <td>
                <SelectEditProfile
                  list={shifts}
                  formik={formik}
                  field="shift"
                  label="Turno"
                  key={shifts.length}
                  sm={3}
                  disabled={unclickable}
                />
              </td>
            </tr>
            <InputEditProfile
              formik={formik}
              field="branchName"
              label="Sucursal"
              placeholder="Sucursal"
              sm={3}
              unclickable={true}
            />
            <InputEditProfile
              formik={formik}
              field="comments"
              label="Comentarios"
              placeholder="Comentarios"
              sm={3}
              unclickable={true}
            />
            <tr className="w-100" style={{ overflow: "hidden" }}>
              <td colSpan={2} className="ps-5">
                <CustomCheckboxField
                  formik={formik}
                  classInput="ps-4 ms-5 w-75"
                  field="shiftManager"
                  label="Jefe de turno"
                  mdLabel={2}
                  disable={unclickable}
                />
              </td>
            </tr>

            {partner.partnerStatus.name === "Baja" && (
              <InputEditProfile
                formik={formik}
                field="banDate"
                label="Fecha de baja"
                placeholder="Fecha de baja"
                sm={3}
                type="date"
              />
            )}

            <tr className="w-100" style={{ overflow: "hidden" }}>
              <td colSpan={3} className="ps-5">
                <div className={`row mb-15px fs-13px d-flex align-items-center`}>
                  <label className={`form-label col-form-label col-md-2 text-end me-2`}>
                    Días libres:
                  </label>
                  <div className="col-md-3">

                    <button
                      className="btn btn-primary"
                      onClick={() => setShowModal(true)}>Editar</button>
                  </div>
                </div>
              </td>
            </tr>


            {!unclickable && (
              <tr className="highlight">
                <td className="field">&nbsp;</td>
                <td className="">
                  <button
                    type="submit"
                    className="btn btn-secondary w-150px ms-5px"
                    style={{ marginRight: "4%" }}
                    onClick={() => navigate("/colaboradores")}
                  >
                    Regresar
                  </button>
                  <button
                    type="submit"
                    className="btn btn-azul w-150px"
                    style={{ marginRight: "4%" }}
                    onClick={() => formik.handleSubmit()}
                    disabled={!formik.dirty}
                  >
                    Actualizar datos
                  </button>
                </td>
              </tr>
            )}

            {/* HISTORIAL AQUI DE PERFIL */}
            {user.rol !== "Lider de sucursal" && (
              <tr className="highlight">
                <td className={`field`} style={{ color: `black` }}>
                  <h4 className="pt-3"></h4>
                </td>
                <td>
                  <Box>
                    <Tabs
                      value={value}
                      onChange={handleChange}
                      aria-label="basic tabs example"
                    >
                      <Tab label="Reintregraciones" {...a11yProps(0)} />
                      <Tab label="Cambios de posición" {...a11yProps(1)} />
                      <Tab label="Cambios de sucursal " {...a11yProps(2)} />
                      {
                        Boolean(user.rol !== 'Administrador-' && user.rol !== 'Líder de líderes' )&& <Tab label="Cambios de salario" {...a11yProps(3)} /> 
                      }
                    </Tabs>
                  </Box>
                  <CustomTabPanel value={value} index={0}>
                    <div className={`col-md-10`}>
                      <ApplicationListReinstatements
                        applications={applications}
                        size="sm"
                      />
                    </div>
                  </CustomTabPanel>
                  <CustomTabPanel value={value} index={1}>
                    <div className={`col-md-10`}>
                      <ApplicationListChangePosition
                        applications={applications}
                        size="sm"
                      />
                    </div>
                  </CustomTabPanel>
                  <CustomTabPanel value={value} index={2}>
                    <div className={`col-md-10`}>
                      <ApplicationListChangeBranch
                        applications={changeBranch}
                        size="sm"
                      />
                    </div>
                  </CustomTabPanel>
                  <CustomTabPanel value={value} index={3}>
                    <div className={`col-md-10`}>
                      <ApplicationListChangeSalary
                        applications={changeSalary}
                        size="sm"
                      />
                    </div>
                  </CustomTabPanel>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      <ModalContainer
        title="Modificar salario"
        open={modalSalary}
        closeFn={() => setModalSalary(false)}
        children={
          <UpdateSalary
            closeFn={() => setModalSalary(false)}
            refreshFn={async () => {
              await reloadPage();
              await fetchApplications();
            }}
            setLoading={(value) => setLoading(value)}
            dataSalary={salary}
          />
        }
      />
      <Loader isLoading={loading} />
      <ModalContainer
        open={showModal}
        title="Días libres del colaborador"
        closeFn={() => setShowModal(false)}
      >
        <div className="form-group">
          <CustomSelectField
            formik={formik}
            field="week"
            label="Semana"
            list={weeks}
            sm={6}
          />
        </div>
        <div className="d-flex w-100 my-3 col-md-12">
          <label
            className={`form-label col-form-label col-md-3 text-end pe-4`}
          >
            Día(s)*
          </label>
          <div className="col-9 d-flex flex-row">
            {weekDays.map((day, index) => (
              <button
                key={index}
                type="button"
                onClick={() => {
                  const isSelected = selectedDays.includes(index);
                  setSelectedDays(
                    isSelected
                      ? selectedDays.filter(dayIndex => dayIndex !== index)
                      : [...selectedDays, index]
                  );
                }}
                className={`btn ${selectedDays.includes(index) ? "btn-primary" : "btn-outline-secondary"} rounded-pill mx-1`}
                title={day.name}
                style={{
                  width: '30px',
                  height: '30px',
                  textTransform: 'capitalize',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                {day.name.slice(0, 1)}
              </button>
            ))}
          </div>

        </div>
        <div className="d-flex flex-row mt-5">
          <button className="btn btn-secondary ms-auto me-2" onClick={() => setShowModal(false)}>Cancelar</button>
          <button className="btn btn-primary" onClick={updateFreeDays}>Guardar</button>
        </div>
      </ModalContainer>
    </div>
  );
};

export default InformationPosition;

import React, { useEffect } from "react";
import { getHasMotoPartner, getProceedingByPartnerAndType } from "../../services/proceedings.service.ts";
import ModalContainer from "../ModalContainer.tsx";
import AddNewFile from "./AddNewFile.tsx";
import Loader from "../Loader.tsx";
import DocumentsViewer from "./DocumentsViewer.tsx";
import { useSelector } from "react-redux";
import { RootState } from "../../store/index.ts";
import userProfileImg from "../../assets/img/userProfile.jpeg";
import LegacyDocItem from "./LegacyDocItem.tsx";
import { FileType } from "../../interfaces/others/ILegacyDocument.ts";
import { notification } from "../../utils/Notifications.tsx";
import { toCamelCase } from "../../hooks/toCameCase.tsx";

const DocumentItem = ({
  partnerId,
  file,
  loadFiles,
  documentsArray,
  partnerObject,
  reloadPage,
  fetchImages,
  fetchImagesByName,
}) => {
  const [showModal, setShowModal] = React.useState<boolean>(false);
  const [fileData, setFileData] = React.useState<any[] | null>(null);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [editing, setEditing] = React.useState<boolean>(false);
  const { user } = useSelector((state: RootState) => state.auth);
  const [legacyDoc, setLegacyDocuments] = React.useState<FileType | null>(
    documentsArray.find((doc) => doc.typeFile === file?.name)
  );

  const formatDate = (date: string) => {
    const newDate = new Date(date);

    return `${newDate.getDate()}/${
      newDate.getMonth() + 1
    }/${newDate.getFullYear()}`;
  };

  const getFileInformation = async (file) => {
    setLoading(true);
    try {
      if (file.id === "motoDocument") {
        const hasMotoData = await getHasMotoPartner(partnerId);
        const updatedMotoFiles = hasMotoData.fileMotorcycleUploaded
          ? [...hasMotoData.fileDetails]
          : [];
        const motoDataList = updatedMotoFiles.map((motoFile) => ({
          id: file.id,
          fields: [],
          createdAt: new Date().toISOString(),
          updateAt: new Date().toISOString(),
          documentType: {
            id: file.id,
            name: file.name,
            fields: [],
          },
          file: motoFile,
          uploadBy: hasMotoData.uploadBy,
          url: motoFile.url,
        }));
        setFileData(motoDataList);
        console.log("MOTO DATA ACTUALIZADA", motoDataList);
      } else {
        const data = await getProceedingByPartnerAndType(partnerId, file.id);
        setFileData(data);
      }
    } catch (error) {
      console.error(error);
      notification(
        "Error",
        "Ocurrió un error al recuperar los documentos",
        "danger"
      );
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getFileInformation(file);
  }, []);

  useEffect(() => {
    setLegacyDocuments(
      documentsArray.find((doc) => doc.typeFile === file?.name)
    );
  }, [file, documentsArray]);

  useEffect(() => {
    getFileInformation(file);
  }, []);

  if (legacyDoc && legacyDoc.files.length > 0 && user.rol === "Trabajador")
    return null;

  if (
    (legacyDoc?.typeFile ===
      "Pruebas psicométricas aplicadas por reclutamiento" ||
      legacyDoc?.typeFile ===
        "Alta en el IMSS (alta ingresada por contabilidad, no se visualiza este rubro)") &&
    user.rol === "Trabajador"
  )
    return null;

  if (file?.name === "Notas del reclutador") return null;

  if (
    user.rol === "Contador" &&
    file?.name !==
      "Alta en el IMSS (alta ingresada por contabilidad, no se visualiza este rubro)"
  )
    return null;

  if (
    user.rol !== "Contador" &&
    file?.name ===
      "Alta en el IMSS (alta ingresada por contabilidad, no se visualiza este rubro)" &&
    user.rol !== "Administrador" &&
    user.rol !== "Administrador-"
  )
    return null;

  if (
    user.rol !== "Contador" &&
    file?.name === "Constancia de semanas cotizadas en el IMSS" &&
    user.rol !== "Administrador" &&
    user.rol !== "Administrador-" &&
    user.rol !== "Trabajador"
  )
    return null;

  if (legacyDoc) {
    return (
      <LegacyDocItem
        legacyDoc={legacyDoc}
        obligatory={file?.obligatory}
        partnerObject={partnerObject}
        closeFn={() => {}}
        refetch={(name) => {
          setLoading(true);
          fetchImagesByName(name);
          setLoading(false);
        }}
      />
    );
  }
  // if(file?.name === 'Medio de transporte(si es motocicleta es de alto riesgo uso interno)') 
  //   return <React.Fragment></React.Fragment>

  return (
    <div className="timeline-item">
      {/* BEGIN timeline-time */}
      <div className="timeline-time">
        <span className="date">
          {fileData && fileData[0] && formatDate(fileData[0]?.updateAt)}
        </span>
        <span className="time">
          {file?.obligatory ? "Obligatorio" : "Opcional"}
        </span>
      </div>
      <div className="timeline-icon">
        <a>&nbsp;</a>
      </div>
      <div className="timeline-content">
        {/* BEGIN timeline-header */}
        <div className="timeline-header">
          <div className="user-image">
            {fileData && fileData[0]?.uploadBy?.name && (
              <img
                src={userProfileImg}
                alt=""
                className="rounded-circle me-2"
                style={{ height: "50px" }}
              />
            )}
          </div>
          <div className="username fs-1 d-flex">
            {fileData && fileData[0]?.uploadBy?.name ? (
              <p className="fs-12px">
                Subido por <br />
                <span className="fs-16px">
                  {toCamelCase(
                    `${fileData[0]?.uploadBy?.name} ${fileData[0]?.uploadBy?.lastName}`
                  )}
                </span>
              </p>
            ) : (
              <span className="fs-16px">Archivo pendiente de subir</span>
            )}
          </div>
          <div>
            <a
              onClick={() => {
                setEditing(true);
                setShowModal(true);
              }}
              href="#/"
              className="btn btn-lg border-0 rounded-pill w-40px h-40px p-0 d-flex align-items-center justify-content-center"
            >
              <i className="fa fa-edit text-gray-600" />
            </a>
          </div>
        </div>

        <div className="timeline-body">
          <p className="fs-2 fw-600">
            {file?.name === "Constancia de semanas cotizadas en el IMSS"
              ? "Asignación de Número de Seguridad Social."
              : file?.name}
          </p>
          <div style={{ marginTop: "-10px" }}>
            {fileData &&
              fileData[fileData.length - 1]?.fields.map((field, index) => (
                <p key={index} className="fs-12px">
                  {field.field}: {field.value}
                </p>
              ))}
          </div>
          <div className="my-4">
            {fileData && fileData.length > 0 ? (
              <div className="row">
                <DocumentsViewer fileData={fileData} />
              </div>
            ) : (
              <div className="d-flex align-items-center">
                <button
                  className="btn btn-primary fs-16px"
                  style={{ gap: 10 }}
                  onClick={() => setShowModal(true)}
                >
                  <i className="fa-solid fa-cloud-arrow-up" />
                  Subir documento
                </button>
              </div>
            )}
          </div>
        </div>
        <ModalContainer
          open={showModal}
          title="Subir documento"
          size="lg"
          closeFn={() => setShowModal(false)}
          children={
            <AddNewFile
              partner={partnerId}
              fields={file?.fields || []}
              fileTypeId={file?.id}
              loadFiles={loadFiles}
              partnerObject={partnerObject}
              initialValues={
                fileData && fileData.length > 0
                  ? fileData[fileData.length - 1].fields.reduce(
                      (acc, field) => {
                        acc[field.field] = field.value;
                        return acc;
                      },
                      {}
                    )
                  : {}
              }
              initialFiles={fileData}
              editing={editing}
              closeFn={() => {
                setShowModal(false);
              }}
              setLoading={setLoading}
              reloadPage={reloadPage}
              refetch={() => {
                setLoading(true);
                getFileInformation(file);
                setLoading(false);
              }}
            />
          }
        />
      </div>
      <Loader isLoading={loading} />
    </div>
  );
};

export default DocumentItem;

import { useState, useEffect } from "react";
import { fetchAssistanceByTwoID } from "../../../services/assistenceCode.ts";
import S3Service from "../../../services/s3.service.ts";

const useFetchCheckDetailsTwoIds = () => {
  const [checkDetails, setCheckDetails] = useState<any[]>([]);
  const [openCheckDetails, setOpenCheckDetails] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [profileImage, setProfileImage] = useState("");

  const fetchProfileImage = async (curp: string) => {
    try {
      const profileUrl = await S3Service.getFile("PERFIL_" + curp);
      setProfileImage(profileUrl.url);
    } catch (error) {
      setProfileImage(
        "https://developers.elementor.com/docs/assets/img/elementor-placeholder-image.png"
      );
      console.error("Error fetching profile image:", error);
    }
  };

  const fetchCheckDetails = async (
    code?: string,
    code2?: string,
    curp?: string
  ) => {
    setLoading(true);
    setError(null);
    try {
      const response = await fetchAssistanceByTwoID(code, code2);
      setCheckDetails(response.data);
      setOpenCheckDetails(true);

      if (curp) {
        await fetchProfileImage(curp);
      }
    } catch (error) {
      console.error("Error al cargar los detalles del check", error);
      setError("Error al cargar los detalles del check");
    } finally {
      setLoading(false);
    }
  };

  return {
    checkDetails,
    openCheckDetails,
    setOpenCheckDetails,
    loading,
    error,
    fetchCheckDetails,
    profileImage,
    setProfileImage,
  };
};

export default useFetchCheckDetailsTwoIds;

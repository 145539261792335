import React from 'react'
import { useAppSettings } from '../../hooks/useAppSettings.tsx';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store/index.ts';
import { resetState, setLoading, setSucursales, setTotal } from '../../store/slices/sucursalesSlice.ts';
import { notification } from '../../utils/Notifications.tsx';
import { paginateSucursales } from '../../services/branch.service.ts';
import Title from '../../components/Title.tsx';
import Loader from '../../components/Loader.tsx';
import FilterSucursales from '../../components/catalogo/Sucursales/FilterSucursales.tsx';
import TableSucursales from '../../components/catalogo/Sucursales/TableSucursales.tsx';
import PaginationSucursales from '../../components/catalogo/Sucursales/PaginationSucursales.tsx';
import Layout from '../../components/Layout.tsx';

const Sucursales = () => {
  useAppSettings();
  const { user } = useSelector((state: RootState) => state.auth);
  const dispatch = useDispatch();
  const { page, limit, param, loading, fetch, query, sortBy, defaultSort } =
    useSelector((state: RootState) => state.sucursales);

  React.useEffect(() => {
    fetchSucursales();
  }, [page, limit, param, fetch, query, sortBy, defaultSort ]);

  const fetchSucursales = async () => {
    dispatch(setLoading(true));
    try {
      const offset = page === 0 ? 0 : page * limit;
      let queryToSend = query + `&sort=${sortBy}&order=${defaultSort}&limit=${limit}&offset=${offset}`;
      if (queryToSend.length === 0) {
        queryToSend = "type=all";
      }
      const response = await paginateSucursales(queryToSend);
      console.log('branches: ',response.branches);
      dispatch(setSucursales(response.branches));
      dispatch(setTotal(response.total));
    } catch (error) {
      notification("Error", error.message, "danger");
    } finally {
      dispatch(setLoading(false));
    }
  }

  React.useEffect(() => {
    dispatch(resetState());
  }, []);

  return (
    <Layout>
      <Title baseTitle="Sucursales" basePath="/sucursales" title="Sucursales" />
      <div className="card border-0 m-4">
        <div className="tab-content p-3">
          <div className="">
            {user?.rol !== 'Líder de líderes' && <FilterSucursales rol={user.rol} />}
            <TableSucursales  user={user} refetch={fetchSucursales} />
            <PaginationSucursales  rol={user.rol} />
          </div>
        </div>
      </div>
      <Loader isLoading={loading} />
    </Layout>
  )
}

export default Sucursales
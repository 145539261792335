import React from "react";
import Layout from "../../../Layout.tsx";
import Title from "../../../Title.tsx";
import { useAppSettings } from "../../../../hooks/useAppSettings.tsx";
import useGetId from "../../../../hooks/useGetId.tsx";
import TableInassistence from "./TableInassistence.tsx";
import { notification } from "../../../../utils/Notifications.tsx";
import moment from "moment";
import { findWihoutAssistance } from "../../../../services/assistanceCheck.service.ts";
import { PartnerWithoutAssistance } from "../../../../models/PartnerWithoutAssistance.ts";
import ModalContainer from "../../../ModalContainer.tsx";
import ModalInassistance from "./ModalInassistance.tsx";
import CustomSelectField from "../../../formik/CustomSelectField.tsx";
import { useSelector } from "react-redux";
import { RootState } from "../../../../store/index.ts";
import { useFormik } from "formik";
import { readBranches } from "../../../../services/branch.service.ts";

const Inassistence = () => {
  useAppSettings();
  const id = useGetId();
  const [loading, setLoading] = React.useState(true);
  const [records, setRecords] = React.useState<PartnerWithoutAssistance[]>([]);
  const [open, setOpen] = React.useState(false);
  const [record, setRecord] = React.useState<PartnerWithoutAssistance | null>();
  const { user } = useSelector((state: RootState) => state.auth);
  const [branches, setBranches] = React.useState([]);

  const fetchWithoutAssistance = async (branchId = id) => {
    setLoading(true);
    try {
      if (["Administrador", "Administrador-"].includes(user.rol) && branches.length === 0) {
        const branchList = await readBranches();
        setBranches(branchList);
      }
      const response = await findWihoutAssistance(
        moment().format("YYYY-MM-DD"),
        branchId
      );
      setRecords(response);
    } catch (error) {
      notification("error", error.message, "danger");
    } finally {
      setLoading(false);
    }
  };

  const formik = useFormik({
    initialValues: {
      branchId: id,
    },
    onSubmit: async (values) => {
      await fetchWithoutAssistance(values.branchId);
    },
  });

  React.useEffect(() => {
    fetchWithoutAssistance(id);
  }, [id]);

  return (
    <React.Fragment>
      <Layout loading={loading}>
        <Title
          baseTitle="Sucursales"
          basePath="/sucursales"
          activeTitle="Cubrir Puestos Urgentes"
          title="Cubrir Puestos Urgentes"
        />

        <div className="card border-0 m-4 rounded">
          <div className="tab-content p-3">
            {["Administrador", "Administrador-"].includes(user.rol) && (
              <form onSubmit={formik.handleSubmit}>
                <CustomSelectField
                  formik={formik}
                  field="branchId"
                  label="Sucursal"
                  list={branches}
                  fieldName="branchName"
                  sm={5}
                  smLabel={3}
                  onChange={(e) => {
                    formik.handleChange(e);
                    formik.handleSubmit();
                  }}
                />
              </form>
            )}
            <TableInassistence
              data={records}
              refetch={() => fetchWithoutAssistance(formik.values.branchId)}
              openModal={(record) => {
                setRecord(record);
                setOpen(true);
              }}
            />
          </div>
        </div>
      </Layout>

      <ModalContainer
        open={open}
        title="Cubrir puesto"
        closeFn={() => setOpen(false)}
        children={
          <ModalInassistance
            close={() => setOpen(false)}
            record={record ?? ({} as any)}
            refetch={() => fetchWithoutAssistance(formik.values.branchId)}
          />
        }
      />
    </React.Fragment>
  );
};

export default Inassistence;

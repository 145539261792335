import React, { useEffect, useState } from "react";
import { useAppSettings } from "../../../../hooks/useAppSettings.tsx";
import Layout from "../../../Layout.tsx";
import Title from "../../../Title.tsx";
import { Panel, PanelHeader } from "../../../panel/panel.jsx";
import { readSchedulesOrganigram } from "../../../../services/branchSchedule.service.ts";
import S3Service from "../../../../services/s3.service.ts";
import ModalContainer from "../../../ModalContainer.tsx";
import { NodeDatum } from "../../../../interfaces/others/INodeDatum.ts";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "../../../../store/index.ts";
import { getAntiguedad } from "../../../../utils/constanst.js";

const BranchOrganigrama = () => {
  useAppSettings();
  const { id: ipParams } = useParams();
  const { user } = useSelector((state: RootState) => state.auth);
  let id = user.rol === "Lider de sucursal" ? user.branchId : ipParams;

  const [orgChart, setOrgChart] = useState<NodeDatum | null>(null);
  const [profileImages, setProfileImages] = useState<{ [key: string]: string }>(
    {}
  );
  const [replacementNodes, setReplacementNodes] = useState<{
    [key: string]: boolean;
  }>({});
  const [selectedNode, setSelectedNode] = useState<NodeDatum | null>(null);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await readSchedulesOrganigram(Number(id));
        setOrgChart(data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, []);

  const fetchProfileImage = async (curp: string) => {
    try {
      const profileUrl = await S3Service.getFile("PERFIL_" + curp);
      setProfileImages((prev) => ({ ...prev, [curp]: profileUrl.url }));
    } catch (error) {
      setProfileImages((prev) => ({
        ...prev,
        [curp]:
          "https://developers.elementor.com/docs/assets/img/elementor-placeholder-image.png",
      }));
      console.error("Error fetching profile image:", error);
    }
  };

  const handleToggleReplacement = (id: number) => {
    setReplacementNodes((prev) => ({
      ...prev,
      [id]: !prev[id],
    }));
  };

  const getBorderColor = (node: NodeDatum) => {
    if (node.assistanceCheck?.status === "Asistencia") {
      return "#72BF78";
    }
    if (!node.startTime) {
      return "#B7B7B7";
    }

    const [startHour, startMinute] = node.startTime
      .split(" - ")[0]
      .split(":")
      .map(Number);
    const now = new Date();
    const checkInTime = new Date();
    checkInTime.setHours(startHour, startMinute, 0, 0);

    const diffInMinutes = (now.getTime() - checkInTime.getTime()) / (1000 * 60);

    if (diffInMinutes < 0) {
      return "#B7B7B7";
    }
    if (diffInMinutes <= 15) {
      return "#FFD700";
    }
    return "#FF0000";
  };

  const renderEmployee = (node: NodeDatum) => {
    if (!node) return null;
    const curpToFetch = replacementNodes[node.id]
      ? node.replacementOf?.partner?.person?.curp
      : node.curp;

    if (curpToFetch && !profileImages[curpToFetch]) {
      fetchProfileImage(curpToFetch);
    }

    const profileImage =
      curpToFetch && profileImages[curpToFetch]
        ? profileImages[curpToFetch]
        : "https://developers.elementor.com/docs/assets/img/elementor-placeholder-image.png";

    const borderColor = getBorderColor(node);

    return (
      <div
        className="text-center mx-3 position-relative"
        style={{ width: "120px", cursor: "pointer" }}
        onClick={() => {
          setSelectedNode(node);
          setShowModal(true);
        }}
      >
        {node.replacementOf && (
          <button
            className="position-absolute bg-dark text-white border-0 rounded-circle"
            style={{
              width: 30,
              height: 30,
              top: 0,
              right: 20,
              zIndex: 500,
              border: "4px solid #f44336",
            }}
            onClick={(e) => {
              e.stopPropagation();
              handleToggleReplacement(node.id);
            }}
          >
            <i className="fas fa-exchange"></i>
          </button>
        )}

        <div
          className="ms-2 border rounded-circle d-flex justify-content-center align-items-center"
          style={{
            width: 100,
            height: 100,
            overflow: "hidden",
          }}
        >
          <img
            style={{ border: `4px solid ${borderColor}` }}
            src={profileImage}
            alt={node.name}
            className="w-100 h-100 rounded-circle"
          />
        </div>

        <h6
          className="mt-2 mb-0 text-truncate"        >
          {replacementNodes[node.id]
            ? node.replacementOf?.partner?.person?.firstName
            : node.name}
        </h6>
        <small className="text-muted ">
          {replacementNodes[node.id]
            ? node.replacementOf?.positionName
            : node.position}
        </small>

        {replacementNodes[node.id] && (
          <p className="mt-0 text-danger fw-bold">Reemplazado</p>
        )}
      </div>
    );
  };

  const getEmployeesByShift = (shift: string) => {
    if (!orgChart) return [];

    let employees: NodeDatum[] = [];
    let employeesLeader: NodeDatum[] = [];

    orgChart.children?.forEach((leader) => {
      if (leader.shift === shift) {
        employeesLeader.push(leader);
      }
      leader.children?.forEach((subordinate) => {
        if (subordinate.shift === shift) {
          employees.push(subordinate);
        }
      });
    });

    return employeesLeader.length > 0
      ? employeesLeader.map((leader) => ({
          ...leader,
          children: employees.filter((e) => e.shift === shift),
        }))
      : employees;
  };

  return (
    <Layout>
      <Title
        baseTitle="Sucursales"
        basePath="/sucursales"
        activeTitle="Organigrama de trabajadores"
        title="Organigrama de trabajadores"
      />
      <div className="card border-0 m-4 rounded">
        <Panel>
          <PanelHeader noButton={true} className="bg-azul">
            Historial de horarios
          </PanelHeader>

          <div id="org-chart-container">
            <div className="d-flex flex-column align-items-center mt-5">
              {orgChart && renderEmployee(orgChart)}
            </div>
            <div className="container text-center">
              <div className="row">
                {["Matutino", "Intermedio", "Vespertino"].map((shift) => {
                  const employeesByShift = getEmployeesByShift(shift);
                  const hasLeader = employeesByShift.some(
                    (emp) => emp.children
                  );

                  return (
                    <div className="col" key={shift}>
                      <h5>{shift}</h5>
                      <div className="d-flex justify-content-center flex-wrap mt-4">
                        {hasLeader ? (
                          employeesByShift.map((employee) =>
                            employee.children ? renderEmployee(employee) : null
                          )
                        ) : (
                          <div
                            className="invisible"
                            style={{ width: "120px", height: "180px" }}
                          ></div>
                        )}
                      </div>
                      <div className="d-flex justify-content-center flex-wrap mt-2">
                        {employeesByShift.map((employee) =>
                          employee.children
                            ? employee.children.map(renderEmployee)
                            : renderEmployee(employee)
                        )}
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </Panel>
      </div>

      {selectedNode && (
        <ModalContainer
          open={showModal}
          title="Detalles del colaborador"
          closeFn={() => setShowModal(false)}
        >
          <div>
            <div className="p-2">
              <div className="d-flex align-items-center">
                <div className="profile-pic-container me-3">
                  <img
                    src={
                      profileImages[selectedNode.curp] ||
                      "https://developers.elementor.com/docs/assets/img/elementor-placeholder-image.png"
                    }
                    className="rounded-circle"
                    style={{ width: 50, height: 50 }}
                    alt="Profile"
                  />
                </div>
                <div className="ml-3">
                  <div>
                    <h5 className="card-title">{selectedNode.name}</h5>
                    <p className="card-text">{selectedNode.position}</p>
                  </div>
                  {selectedNode.partnerId && (
                    <a
                      href={`/colaboradores/detalles/${selectedNode.partnerId}`}
                      className="text-primary"
                    >
                      Ir al perfil
                    </a>
                  )}
                </div>
              </div>
            </div>
            <div className="mt-3 p-2 border rounded">
              <p>
                <strong>Posición:</strong> {selectedNode.position}
              </p>
              <p>
                <strong>Antigüedad:</strong>{" "}
                {getAntiguedad(selectedNode.candidate?.recruitmentDate) ||
                  "No disponible"}
              </p>
              <p>
                <strong>Horario:</strong>{" "}
                {selectedNode.startTime && selectedNode.endTime
                  ? `${selectedNode.startTime.split(" - ")[0]} - ${
                      selectedNode.endTime.split(" - ").slice(-1)[0]
                    }`
                  : "No disponible"}
              </p>
              <p>
                <strong>Turno:</strong> {selectedNode.shift}
              </p>
            </div>
          </div>
        </ModalContainer>
      )}
    </Layout>
  );
};

export default BranchOrganigrama;

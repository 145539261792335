import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store/index.ts";
import { Partner } from "../../models/Partner.ts";
import { getCurrencyFormat } from "../../utils/index.ts";
import moment from "moment";
import "moment/locale/es";
import { useNavigate } from "react-router-dom";
import EmptyImage from "../../assets/img/ic_content.svg";
import { toCamelCase } from "../../hooks/toCameCase.tsx";
import {
  setCurrentUser,
  setModalAcountant,
} from "../../store/slices/colaboradoresSlice.ts";
moment.locale("es");

const TableColaboradoresContador = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { colaboradores, fetch } = useSelector(
    (state: RootState) => state.colaboradores
  );
  const { user } = useSelector((state: RootState) => state.auth);

  const formatDate = (date: string) => {
    const fechaFormateada = moment(date).format("dddd, D [de] MMMM [de] YYYY");

    return fechaFormateada;
  };

  const formatDateWithoutTime = (date: string) => {
    const fechaFormateada = moment(date).format("dddd, D [de] MMMM [de] YYYY");

    return fechaFormateada;
  };

  return (
    <div className="table-responsive mb-3">
      <table className="table table-hover table-panel text-nowrap align-middle mb-0">
        <thead>
          <tr>
            <th>Nombre completo</th>
            <th>Sucursal</th>
            <th>Puesto</th>
            <th>Salario</th>
            <th>Salario IMSS</th>
            <th>Status</th>
            <th>Fecha de baja</th>
            <th>Fecha de entrada</th>
            <th>Acciones</th>
          </tr>
        </thead>
        <tbody>
          {colaboradores?.length > 0 ? (
            colaboradores.map((colaborador: Partner, index) => (
              <tr key={index}>
                <td>
                  {toCamelCase(
                    `${colaborador.person.firstName} ${colaborador.person.lastName} ${colaborador.person.secondLastName}`
                  )}
                </td>

                <td>{colaborador.candidate?.branch.name}</td>
                <td>{colaborador.candidate?.vacancy.position.name}</td>
                <td>
                  {getCurrencyFormat(colaborador.candidate?.monthlySalary)}
                </td>
                <td>
                  {/* {getCurrencyFormat(colaborador.candidate?.monthlySalaryIMSS)} */}
                  {colaborador.candidate?.monthlySalaryIMSS > 0 ? (
                    getCurrencyFormat(colaborador.candidate?.monthlySalaryIMSS)
                  ) : (
                    <div
                      style={{
                        backgroundColor: "rgb(212 149 66)",
                        color: "white",
                        padding: "3px 7px",
                        borderRadius: "10px",
                        display: "inline-block",
                      }}
                    >
                      $0.00
                    </div>
                  )}
                </td>
                <td>
                  <div
                    style={{
                      backgroundColor:
                        colaborador.partnerStatus.name !== "Activo"
                          ? "#c74c4a"
                          : "#4ac792",
                      color: "white",
                      padding: "3px 7px",
                      borderRadius: "10px",
                      display: "inline-block",
                    }}
                  >
                    {colaborador.partnerStatus.name}
                  </div>
                </td>
                <td>
                  {colaborador.partnerStatus.name !== "Activo"
                    ? formatDate(colaborador.banDate)
                    : "No aplica"}
                </td>
                <td>
                  {formatDateWithoutTime(
                    colaborador?.originalRecruitmentDate
                  )}
                </td>
                <td>
                  <a
                    href="#/"
                    className="btn btn-azul me-2"
                    onClick={() =>
                      navigate(`/colaboradores/detalles/${colaborador.id}`)
                    }
                  >
                    <i className="fa-solid fa-circle-info"></i>
                  </a>
                  <a
                    href="#/"
                    className="btn btn-amarillo"
                    onClick={() => {
                      dispatch(setCurrentUser(colaborador.id));
                      dispatch(setModalAcountant(true));
                    }}
                  >
                    <i className="fa-solid fa-pen"></i>
                  </a>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan={9}>
                <div className="py-4">
                  <div className="d-flex">
                    <img src={EmptyImage} alt="" className="mx-auto w-250px" />
                  </div>
                  <h5 className="text-secondary text-center fs-20px">
                    Aún no hay colaboradores
                  </h5>
                </div>
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default TableColaboradoresContador;

import React, { useEffect, useState } from "react";
import {
  deleteFileMotorcycle,
  deleteFileProceeding,
} from "../../services/proceedings.service.ts";
import ModalContainer from "../ModalContainer.tsx";
import { notification } from "../../utils/Notifications.tsx";
import Dropzone from "react-dropzone";
import S3Service from "../../services/s3.service.ts";

function FileUploader({
  onFilesSelected,
  initialFiles,
  closeFn,
  fields,
  legacyFiles,
  refetch,
}) {
  const [selectedFiles, setSelectedFiles] = useState<any>([]);
  const [fileToDelete, setFileToDelete] = useState<any>(null);
  const [deleteModal, setDeleteModal] = useState(false);

  useEffect(() => {
    if (initialFiles) {
      const fileNames = initialFiles.map((file) => ({
        id: file.id === "motoDocument" ? file.file.id : file.id,
        ifFile: file.file.id,
        originalName: file.file.name + "." + file.file.extension,
        url: file.url,
        documentType: file.documentType,
        idPartner: file.idPartner,
        file: file.file,
      }));
      setSelectedFiles(fileNames);
    } else if (legacyFiles) {
      const fileNames = legacyFiles.files.map((file) => ({
        id: file.id,
        originalName: file.id,
        url: file.url,
      }));
      setSelectedFiles(fileNames);
    }
  }, [initialFiles, legacyFiles]);

  const handleFileChange = (event) => {
    if (legacyFiles) {
      console.log("event: ", event[0].type);
      if (!event[0].type.includes("image") || event[0].type === "image/heic") {
        notification("Error", "Solo puedes subir imágenes", "danger");
        return;
      }

      const files = event;
      setSelectedFiles([...selectedFiles, ...files]);
      onFilesSelected([...selectedFiles, ...files]);
      return;
    }
    const files = event;
    setSelectedFiles([...selectedFiles, ...files]);
    onFilesSelected([...selectedFiles, ...files]); // Llama a la función de retorno con los archivos seleccionados
  };

  const handleRemoveFile = async (file: any) => {
    console.log("file: ", file);
    if (file.documentType && file.documentType.id === "motoDocument") {
      await deleteFileMotorcycle(file?.file?.idPartner, file?.file?.id);
      await refetch();
    } else if (legacyFiles) {
      if (file.id === legacyFiles.files[0].id) {
        await S3Service.replaceFile(
          legacyFiles.files[1].id,
          legacyFiles.files[0].id
        );
      } else {
        await S3Service.deleteFile(file.id);
      }
      await refetch(file.id);
    } else {
      await deleteFileProceeding(file.id);
      await refetch();
    }
    notification(
      "Archivo eliminado",
      "El archivo ha sido eliminado",
      "success"
    );
    closeFn();
  };

  return (
    <div className="row fs-13px col-md-12">
      <label className={`form-label col-form-label col-md-12 text-center`}>
        Documentos
      </label>
      <div className={`col-md-12`}>
        <Dropzone onDrop={(acceptedFiles) => handleFileChange(acceptedFiles)}>
          {({ getRootProps, getInputProps }) => (
            <section>
              <div
                {...getRootProps()}
                className="dropzone-container border rounded p-4 text-center"
              >
                <input {...getInputProps()} />
                <i className="fas fa-cloud-upload-alt fa-3x mb-3"></i>
                <p className="mb-0">
                  Arrastra y suelta archivos aquí o haz clic para seleccionar
                  archivos
                </p>
              </div>
            </section>
          )}
        </Dropzone>
      </div>

      <div className="mt-2">
        {selectedFiles && selectedFiles.length > 0 && (
          <label className={`form-label col-form-label w-100 text-center mt-2`}>
            Documentos
          </label>
        )}
        <div
          className="overflow-auto"
          style={{ width: "100%", maxWidth: "800px" }}
        >
          <ul className="list-unstyled d-flex flex-nowrap">
            {selectedFiles.map(
              (
                file: {
                  originalName: string;
                  url: string;
                  id: number;
                  type: string;
                  documentType?: any;
                  idPartner?: number;
                  file?: any;
                },
                index
              ) => (
                <li
                  key={index}
                  className="position-relative mt-3 me-3 d-flex justify-content-center align-items-center bg-secondary"
                  style={{ minWidth: "200px" }}
                >
                  {file.type === "application/pdf" ||
                  file?.originalName?.endsWith(".pdf") ? (
                    file?.url ? (
                      <div
                        style={{
                          width: `100%`,
                          height: "400px",
                          backgroundColor: "#ccc",
                          borderRadius: "5px",
                        }}
                        className="d-flex justify-content-center align-items-center"
                      >
                        <i className="fa-regular fa-file fs-59px accordion-body me-2" />
                        <p className="text-center fs-1 fw-600 my-auto">
                          {file.originalName}
                        </p>
                      </div>
                    ) : (
                      <iframe
                        src={URL.createObjectURL(file)}
                        title={file.originalName}
                        className="pdf-preview"
                        style={{ width: "100%", height: "400px" }}
                      ></iframe>
                    )
                  ) : (
                    <img
                      src={file.url || URL.createObjectURL(file)}
                      alt={file.originalName}
                      className="img-fluid"
                      style={{
                        maxWidth: "100%",
                        height: "200px",
                        objectFit: "cover",
                      }}
                    />
                  )}

                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      position: "absolute",
                      top: 0,
                      right: 0,
                      justifyContent: "flex-end",
                      padding: "1px",
                    }}
                  >
                    {legacyFiles && legacyFiles.files.length !== 1 && (
                      <div style={{ display: "flex", flexDirection: "row" }}>
                        <button
                          className="btn btn-danger"
                          style={{ zIndex: 1, alignSelf: "flex-start" }}
                          onClick={() => {
                            setFileToDelete(file);
                            setDeleteModal(true);
                          }}
                        >
                          <i className="fas fa-trash-alt"></i>
                        </button>
                        {file.url && (
                          <button
                            onClick={() => window.open(file.url, "_blank")}
                            style={{
                              cursor: "pointer",
                              padding: "5px 10px",
                              borderRadius: "5px",
                              border: "none",
                              backgroundColor: "#007bff",
                              color: "white",
                              marginLeft: "5px",
                            }}
                          >
                            <i className="fa-solid fa-download"></i>
                          </button>
                        )}
                      </div>
                    )}
                  </div>

                  {!legacyFiles && (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        position: "absolute",
                        top: 0,
                        right: 0,
                        zIndex: 1,
                      }}
                    >
                      <button
                        className="btn btn-danger m-2"
                        onClick={() => {
                          if (file.id) {
                            setFileToDelete(file);
                            setDeleteModal(true);
                          } else {
                            setSelectedFiles(
                              selectedFiles.filter(
                                (selectedFile) => selectedFile !== file
                              )
                            );
                          }
                        }}
                      >
                        <i className="fas fa-trash-alt"></i>
                      </button>
                      {file.url && (
                        <a
                          href={file.url || URL.createObjectURL(file)}
                          download={file.originalName}
                          className="btn btn-primary m-2"
                          style={{ marginRight: "50px" }}
                        >
                          <i className="fas fa-download"></i>
                        </a>
                      )}
                    </div>
                  )}
                </li>
              )
            )}
          </ul>
        </div>
      </div>

      <ModalContainer
        open={deleteModal}
        title="Eliminar documento"
        closeFn={() => setDeleteModal(false)}
        children={
          <div className="d-flex flex-column align-items-center px-5">
            <h4 className="my-5">¿Estás seguro de eliminar el archivo?</h4>
            <div className="d-flex justify-content-evenly w-100">
              <button
                className="btn btn-secondary"
                onClick={() => setDeleteModal(false)}
              >
                Cancelar
              </button>
              <button
                className="btn btn-danger"
                onClick={() => {
                  handleRemoveFile(fileToDelete);
                  setDeleteModal(false);
                }}
              >
                Eliminar
              </button>
            </div>
          </div>
        }
      />
    </div>
  );
}

export default FileUploader;

import React from "react";
import moment from "moment";
import { formatDate } from "../../../../utils/index.ts";

const ModalCheckDetailsChequeo = ({
  partnerProfile,
  setShowModal,
  checkDetails,
}: {
  partnerProfile: any;
  setShowModal: (_: boolean) => void;
  checkDetails: any;
}) => {
  if (!checkDetails) {
    return (
      <div className="py-4 text-center">
        <h5 className="text-secondary">
          No se encontraron detalles de chequeo.
        </h5>
      </div>
    );
  }

  const hasAssistanceCheckOut = checkDetails.assistanceCheckOut;

  const renderCheckSection = (check: any, title: string) => {
    const hasCode = check?.code !== null;

    return (
      <>
        <h5 className="fw-bold">{title}</h5>
        <div className="w-100 mb-4 card bg-light p-3">
          <div className="">
            <div className="d-flex justify-content-between">
              <p>
                <strong>Fecha:</strong> {formatDate(check?.checkDate)}
              </p>
              <p className="fw-bold">{check?.status}</p>
            </div>
            <div className="d-flex justify-content-between w-full">
              {hasCode && (
                <>
                  <div className="flex-fill text-center">
                    <img
                      src={check?.photoUrl}
                      alt="Foto tomada"
                      className="img-fluid me-2 border"
                      style={{
                        width: "210px",
                        height: "150px",
                        objectFit: "cover",
                        borderRadius: "8px",
                      }}
                    />
                    <p className="mt-1 fw-bold">Foto tomada</p>
                  </div>
                  <div className="flex-fill text-center">
                    <img
                      src={partnerProfile }
                      alt="Foto perfil"
                      className="ms-2 img-fluid border"
                      style={{
                        width: "210px",
                        height: "150px",
                        objectFit: "cover",
                        borderRadius: "8px",
                      }}
                    />
                    <p className="mt-1 fw-bold">Foto Perfil</p>
                  </div>
                </>
              )}
              {!hasCode && (
                <div className="d-column">
                  <p>
                    <strong>Dispositivo:</strong> {check?.deviceModel || "N/A"}{" "}
                    - {check?.deviceName || "N/A"}
                  </p>
                  <p>
                    <strong>Ubicación:</strong> {check?.location || "N/A"}
                  </p>
                </div>
              )}
            </div>
          </div>
        </div>
      </>
    );
  };

  return (
    <div>
      {renderCheckSection(checkDetails.assistanceCheck, "Entrada")}
      {hasAssistanceCheckOut &&
        renderCheckSection(checkDetails.assistanceCheckOut, "Salida")}
    </div>
  );
};

export default ModalCheckDetailsChequeo;

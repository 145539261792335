import React from "react";
import { OperationalUrgency } from "../../../../models/operationalUrgency";
import { toCamelCase } from "../../../../hooks/toCameCase.tsx";
import EmptyImage from "../../../EmptyImage.tsx";
import { Tooltip } from "@mui/material";

const TableOperationalUrgencyPartners = ({
  records,
}: {
  records: OperationalUrgency;
}) => {
  return (
    <div className="table-sticky mb-3">
      <table className="table table-hover table-panel text-nowrap align-middle mb-0">
        <thead className="sticky-thead table-light text-center">
          <tr>
            <th className="align-middle">Chilito con falta</th>
            <th className="align-middle">Chilito que cubre</th>
            <th className="align-middle">Hora de llegada</th>
            <th className="align-middle">Información de la cobertura</th>
            <th />
          </tr>
        </thead>
        <tbody className="accordion" id="accordionExample">
          {records.lateArrivals.map((lateArrival, index) => (
            <tr
              key={index}
              style={{
                backgroundColor: !lateArrival?.replacement
                  ? "rgb(242 238 102)"
                  : "",
              }}
            >
              <td className="align-middle text-center">
                {toCamelCase(
                  `${lateArrival.partner.person.firstName} ${lateArrival.partner.person.lastName} ${lateArrival.partner.person.secondLastName}`
                )}
              </td>
              <td className="align-middle text-center">
                {lateArrival?.replacement ? (
                  <>
                    {toCamelCase(
                      `${lateArrival?.replacement.partner.person.firstName} ${lateArrival?.replacement.partner.person.lastName} ${lateArrival?.replacement.partner.person.secondLastName}`
                    )}
                  </>
                ) : (
                  "Sin información"
                )}
              </td>
              <td className="align-middle text-center">
                {lateArrival?.replacement?.assistanceCheck?.checkTime ??
                  "Sin información"}
              </td>
              <td className="align-middle text-center">
                {lateArrival?.replacement?.observations ?? "Sin información"}
              </td>

              <td className="align-middle text-center">
                {!lateArrival?.replacement && (
                  <button type="button" className="btn btn-secondary">
                    <i className="fa-solid fa-floppy-disk fa-lg" />
                  </button>
                )}
              </td>
            </tr>
          ))}
          {records.lateArrivals.length === 0 && (
            <tr>
              <td colSpan={4} className="text-center">
                <EmptyImage className="mx-auto w-130px" text="Sin registros" />
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default TableOperationalUrgencyPartners;

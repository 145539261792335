import React from "react";
import moment from "moment";
import { formatDate } from "../../../../utils/index.ts";
// import { formatDateWithoutTimeUtil } from "../../../../utils/constanst";

const ModalCheckDetails = ({
  partnerId,
  setShowModal,
  checkDetails,
}: {
  partnerId: number;
  setShowModal: (_: boolean) => void;
  checkDetails: any;
}) => {

  return (
    <div>
      {!checkDetails ? (
        <div className="py-4 text-center">
          <h5 className="text-secondary">
            No se encontraron detalles de chequeo.
          </h5>
        </div>
      ) : (
        <div
          className="mb-4 card p-3 bg-light d-flex flex-column flex-md-row align-items-center"
          style={{
            borderRadius: "8px",
            maxHeight: "600px",
            overflowY: "auto",
          }}
        >
          {/* Imagen */}
          <div className="w-80 w-md-3 mb-3 mb-md-0 me-md-4">
            {checkDetails.photoUrl && (
              <div className="mt-3">
                <img
                  src={checkDetails.photoUrl}
                  alt="Foto del check"
                  className="img-fluid w-100"
                  style={{
                    width: "100px",
                    height: "100px",
                    objectFit: "cover",
                    borderRadius: "8px",
                  }}
                />
              </div>
            )}
          </div>

          <div className="w-100 w-md-7">
            <h5 className="fw-bold">
              Fecha: {formatDate(checkDetails.checkDate)}
            </h5>
            <p>
              <strong>Hora:</strong> {checkDetails.checkTime}
            </p>
            <p>
              <strong>Estado:</strong> {checkDetails.status}
            </p>
          </div>
        </div>
      )}
    </div>
  );
};

export default ModalCheckDetails;

import React, { useCallback, useEffect, useRef, useState } from "react";
import { Tooltip, Typography } from "@mui/material";
import {
  compactLayout,
  mergePositions,
  transformScheduleToLayout,
  updateColor,
} from "../../../utils/index.ts";
import GridLayout from "react-grid-layout";
import {
  generateGradientBackground,
  getBackgroundColor,
  isOverlapping,
  scheduleModule,
} from "../../../utils/constanst.js";
import { HourRange } from "../../../models/HourRange.ts";
import moment from "moment";
import {
  findByExactDateAndBranch,
  readPositionsByBranchAndShift,
} from "../../../services/branchSchedule.service.ts";
import Loader from "../../Loader.tsx";
import { ScheduleRecord } from "../../../models/ScheduleRecord.ts";
import { ColorScheduleProperties } from "../../../models/ColorSchedule.ts";
import { findConfigurationByKey } from "../../../services/configuration.service.ts";
import { PositionArray } from "../../../models/PositionArray.ts";
import { LayoutItem } from "../../../models/Layout.ts";
import { useWebSocket } from "../../../hooks/useWebSocket.tsx";

const ScheduleCompact = ({
  hours,
  existingsSchedules,
  formik,
  positionsArray,
  setPositionsArray,
  selectedWeek,
  selectedDay = 0,
  turno = 0,
  hoursRange,

  layout,
  deleteItem,
  handleResize,
  handleResizeStop,
  onDragStop,
  setLastItem,

  compactFilters,
  updateLine,
}) => {
  const [loading, setLoading] = useState(true);
  const [percentage, setPercentage] = useState(0);
  const [firstRender, setFirstRender] = useState(true);

  const [schedules, setSchedules] = useState<LayoutItem[]>(layout);
  const [colors, setColors] = useState<ColorScheduleProperties>();
  const [configuration, setConfiguration] = useState<any>();
  const { isConnected, assistancesObserver } = useWebSocket();
  const [transparentItems, setTransparentItems] = useState<string[]>([]);
  const [pressedItem, setPressedItem] = useState<string | null>(null);

  const handleMakeTransparent = (id: string) => {
    setTransparentItems((prev) => [...prev, id]);
  };
  const calculatePercentage = () => {
    const hours = hoursRange;
    const percentageTotal = 100 / hours.length - scheduleModule;

    let currentHour: string | number = new Date().getHours();
    const currentMinute = new Date().getMinutes();

    currentHour = currentHour < 10 ? `0${currentHour}` : currentHour;

    const hourInFormat = `${currentHour}:${currentMinute < 30 ? "00" : "30"}`;

    const currentIndex =
      hours.findIndex((item: HourRange) => {
        if (item.display === "Hora") return false;

        const [start, end] = item.display.split(" - ");

        return hourInFormat >= start && hourInFormat < end;
      }) - 2;

    if (currentIndex < 0) return setPercentage(0);

    const percentage = currentIndex * percentageTotal;

    const percentageMinute = ((currentMinute % 30) / 30) * 3.01912655971;

    setPercentage(percentage + percentageMinute);
  };

  const moveToCurrentTime = () => {
    const element: HTMLElement | null = document.getElementById("lineaActual");
    if (element) {
      element.scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "center",
      });
    }
    setFirstRender(false);
  };

  const fetchDependencies = useCallback(async () => {
    const today = moment().format("YYYY-MM-DD");

    try {
      const configuration = await findConfigurationByKey("scheduleColor");
      setColors(configuration);
      const assistance = await findConfigurationByKey("assistance");
      setConfiguration(assistance);
      const fetchedSchedules = await findByExactDateAndBranch(
        today,
        Number(formik.values?.branchId)
      );

      const transformLayout = transformScheduleToLayout(
        fetchedSchedules,
        configuration,
        assistance
      );
      console.log("transformLayout", transformLayout);
      setSchedules(transformLayout);
    } catch (error) {
      console.error("Error fetching data: ", error);
    } finally {
      setLoading(false);
    }
  }, [formik.values?.branchId]);

  useEffect(() => {
    calculatePercentage();
    const interval = setInterval(() => {
      calculatePercentage();
    }, 60000);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    if (firstRender && percentage > 0) {
      moveToCurrentTime();
    }
    moveToCurrentTime();
  }, [percentage]);

  useEffect(() => {
    fetchDependencies();
  }, []);

  useEffect(() => {
    moveToCurrentTime();
  }, [compactFilters]);

  useEffect(() => {
    if (isConnected) {
      const unsubscribe = assistancesObserver((data) => {
        const { branchId } = data;

        if (branchId === Number(formik.values?.branchId)) {
          fetchDependencies();
        }
      });

      return () => {
        unsubscribe();
      };
    }
  }, [isConnected]);

  return (
    <div className="d-flex flex-row">
      <Loader isLoading={loading} />
      <div
        style={{
          position: "sticky",
          left: -10,
          zIndex: 6,
          backgroundColor: "white",
        }}
      >
        {mergePositions(positionsArray)
          .filter((position) => !position.isDeleted)
          .map((key, index) => {
            return (
              <div
                key={index}
                data-grid={{
                  x: 0,
                  y: index,
                  w: key?.title ? 36 : 2,
                  h: 1,
                  static: true,
                }}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  fontSize: "10px",
                  justifyContent: "center",
                  alignItems: "center",
                  textAlign: "left",
                  borderRight: "2px solid #EEE",
                  borderBottom: `${key?.title ? "3px" : "2px"} solid #EEE`,
                  borderTop: `${key?.title ? "3px solid" : ""} ${
                    key?.title === "Matutino"
                      ? "#FFD700"
                      : key.title === "Vespertino"
                      ? "#FF6347"
                      : "#4682B4"
                  }`,
                  paddingInline: "5px",
                  fontWeight: 600,
                  height: 50,
                  borderLeft: `3px solid ${
                    !key?.shifts
                      ? key.shift === "Matutino"
                        ? "#FFD700"
                        : key.shift === "Vespertino"
                        ? "#FF6347"
                        : "#4682B4"
                      : "#4682B4"
                  }`,
                  backgroundColor: "transparent",
                  width: (2400 / hoursRange?.length) * 2,
                }}
              >
                {key.title && (
                  <p className="w-100 mt-3 fs-15px fw-800 bg">
                    {key?.title ?? key.positionName}
                  </p>
                )}
                <Tooltip title={key?.shifts?.join(", ") ?? ""}>
                  <p className="w-100 mt-auto">{key?.positionName}</p>
                </Tooltip>
              </div>
            );
          })}
      </div>

      <div
        style={{
          position: "relative",
          //cursor: "not-allowed",
        }}
      >
        {/* {JSON.stringify(schedules)} */}
        <GridLayout
          // className="layout"
          cols={hoursRange.length}
          layout={compactLayout(schedules, mergePositions(positionsArray))}
          maxRows={mergePositions(positionsArray)?.length}
          rowHeight={50}
          width={2400}
          preventCollision={false}
          allowOverlap={true}
          isResizable={false}
          isDroppable={false}
          isDraggable={false} 
          draggable={false}
          onResize={handleResize}
          onResizeStop={handleResizeStop}
          onDragStop={onDragStop}
          compactType={null}
          margin={[0, 0]}
          style={{
            background: generateGradientBackground(hoursRange, hours, turno),
            width: `${2400}px`,
            position: "relative",
          }}
          onDrop={(layoutx, layoutItem, _event) => {
            setLastItem(layoutItem);
          }}
        >
          {mergePositions(positionsArray)
            .filter((position) => !position.isDeleted)
            .map((key, index) => {
              return (
                <div
                  key={index}
                  data-grid={{
                    x: 0,
                    y: index,
                    w: key?.title ? 36 : 2,
                    h: 1,
                    static: true,
                  }}
                  style={{
                    fontSize: "10px",
                    display: "none",
                    justifyContent: "center",
                    alignItems: "center",
                    textAlign: "left",
                    borderRight: "2px solid #EEE",
                    borderBottom: `${key?.title ? "3px" : "2px"} solid #EEE`,
                    borderTop: `${key?.title ? "3px solid" : ""} ${
                      key?.title === "Matutino"
                        ? "#FFD700"
                        : key.title === "Vespertino"
                        ? "#FF6347"
                        : "#4682B4"
                    }`,
                    paddingInline: "5px",
                    fontWeight: 600,
                    borderLeft: `3px solid ${
                      key.shift === "Matutino"
                        ? "#FFD700"
                        : key.shift === "Vespertino"
                        ? "#FF6347"
                        : "#4682B4"
                    }`,
                    backgroundColor: "white",
                    position: "sticky",
                    left: 0,
                    zIndex: 3,
                  }}
                >
                  {key.title && (
                    <p className="w-100 mt-3 fs-15px fw-800 bg">
                      {key?.title ?? key.positionName}
                    </p>
                  )}
                  <p className="w-100 mt-auto">{key?.positionName}</p>
                </div>
              );
            })}

          {compactLayout(schedules, mergePositions(positionsArray)).map(
            (item, index) => {
              if (item?.x === undefined || !item?.show) return <></>;
              return (
                <div
                  key={item?.i}
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                    opacity: pressedItem === item.i ? 0.1 : 4, 
                    ...(compactFilters.assistence &&
                      item.assistanceStatus !== "Asistencia" && {
                        display: "none",
                      }),
                    ...(compactFilters.late &&
                      item.assistanceStatus !== "Retardo" && {
                        display: "none",
                      }),
                    ...(compactFilters.toArrive &&
                      item.assistanceStatus !== "Por llegar" && {
                        display: "none",
                      }),
                    ...(compactFilters.withoutAssistence &&
                      item.assistanceStatus && {
                        display: "none",
                      }),
                  }}
                  data-grid={{
                    x: item?.x,
                    y: item?.y,
                    w: item?.w,
                    h: 1,
                    // static: true,
                  }}
                >
                  <div
                    style={{
                      paddingLeft: "8px",
                      width: "100%",
                      borderRadius: "5px",
                      paddingBlock: "4px",
                      ...(compactFilters.resumen && {
                        ...getBackgroundColor(item, index, positionsArray),
                      }),
                      ...(compactFilters.realTime && {
                        backgroundColor: item.color,
                      }),
                      ...(compactFilters.assistence && {
                        backgroundColor: item.color,
                      }),
                      ...(compactFilters.late && {
                        backgroundColor: item.color,
                      }),
                      ...(compactFilters.toArrive && {
                        backgroundColor: item.color,
                      }),
                      ...(compactFilters.withoutAssistence && {
                        backgroundColor: item.color,
                      }),
                    }}
                    className="shadow-sm"
                  >
                    <Tooltip
                      title={`${item.name} | ${item.position} | ${
                        item.w / 2
                      } hrs`}
                      arrow
                    >
                      <div className="d-flex flex-row align-items-center">
                        <Typography fontWeight={500}>
                          <div>{item.name}</div>
                        </Typography>
                        {item.overlap && (
                          <a
                            onMouseDown={(e) => {
                              e.stopPropagation();
                              setPressedItem(item.i);
                            }}
                            onMouseUp={(e) => {
                              e.stopPropagation();
                              setPressedItem(null);
                            }}
                            onMouseLeave={() => setPressedItem(null)}
                            style={{ zIndex: 2 }}
                          >
                            <i className="ms-2 fa-solid fa-eye"></i>
                          </a>
                        )}
                      </div>
                    </Tooltip>
                  </div>
                </div>
              );
            }
          )}
        </GridLayout>

        <div
          id="lineaActual"
          style={{
            position: "absolute",
            top: 0,
            left: `${percentage}%`,
            height: "100%",
            borderLeft: "2px solid red",
            zIndex: 2,
          }}
        />
      </div>
    </div>
  );
};

export default ScheduleCompact;

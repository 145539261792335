import React from "react";
import { Link } from "react-router-dom";

const ButtonLink: React.FC<ButtonLinkProps> = ({ ...props }) => {
  if (props.to) {
    <Link to={props.to} style={{ textDecoration: "none" }}>
      <button
        type={props.type}
        className={props.className}
        style={{ margin: "0 auto" }}
      >
        {props.text}
      </button>
    </Link>;
  }

  return (
    <button
      type={props.type}
      className={props.className}
      style={{ margin: "0 auto" }}
    >
      {props.text}
    </button>
  );
};

export default ButtonLink;

export interface ButtonLinkProps {
  to?: string;
  style?: React.CSSProperties;
  type: "submit" | "reset" | "button" | undefined;
  className: string;
  text: any;
}

import React, { useEffect, useMemo, useState } from "react";
import {
  Scanner,
  IDetectedBarcode,
  useDevices,
} from "@yudiel/react-qr-scanner";
import { notification } from "../../utils/Notifications.tsx";
import moment from "moment";
import {
  registerAssistance,
  authorizeAssistance,
} from "../../services/assistanceCheck.service.ts";
import { findConfigurationByKey } from "../../services/configuration.service.ts";
import {
  fetchPartner,
  getMessagePartner,
  readPartnerByBranches,
} from "../../services/partner.service.ts";
import { useSelector } from "react-redux";
import { RootState } from "../../store/index.ts";
import { findByName } from "../../services/branch.service.ts";
import { isValidForSweetAlert } from "../../validation/schemas/MessagePartnerSchema.ts";
import Swal from "sweetalert2";
import Branch from "../../models/Branch.ts";
import { useWebSocket } from "../../hooks/useWebSocket.tsx";
import Loader from "../../components/Loader.tsx";
import { useDevicesHook } from "../../hooks/useDevices.tsx";
import Webcam from "react-webcam";
import {
  fetchInfoCode,
  registerAssistanceCheckByCode,
} from "../../services/assistenceCode.ts";
import { useFormik } from "formik";
import * as Yup from "yup";
import CustomInput from "../../components/formik/CustomInput.tsx";

const QrScanner = () => {
  const [scannerEnabled, setScannerEnabled] = useState(true);
  const [actualDate, setActualDate] = useState(new Date());
  const [branch, setBranch] = useState<Branch>();
  const [tempUser, setTempUser] = useState({
    nombre: "",
    checkAt: "",
    status: "",
  });
  const [deviceId, setDeviceId] = useState<string | undefined>(undefined);

  const devices = useDevices();
  const [refreshKey, setRefreshKey] = useState(0);
  const [show, setShow] = useState(true);
  const [loading, setloading] = useState(false);
  const { isConnected, triggerUpdate } = useWebSocket();
  const { user } = useSelector((state: RootState) => state.auth);
  const [configuration, setConfiguration] = useState<any>(null);
  const [modeCode, setModeCode] = useState(false);
  const [capturedImage, setCapturedImage] = useState<string | null>(null);
  const [checkCode, setCheckCode] = useState<string | null>(null);
  const webcamRef = React.useRef<Webcam>(null);
  const [facingMode, setFacingMode] = useState("user");
  const [inputCode, SetInputCode] = useState("");
  const [inputLastName, SetInputLastName] = useState("");
  const [messageWildcards, setMessageWildcards] = useState("");
  const toggleFacingMode = () => {
    setFacingMode((prevMode) => (prevMode === "user" ? "environment" : "user"));
  };
  const formik = useFormik({
    initialValues: {
      code: "",
      lastName: "",
    },
    validationSchema: Yup.object({
      code: Yup.string().required("El código es requerido"),
      lastName: Yup.string().required("El primer apellido es requerido"),
    }),
    onSubmit: async (values) => {
      setloading(true);
      if (!webcamRef.current) {
        setloading(false);
        notification("error", "No se pudo acceder a la cámara", "danger");
        return;
      }

      try {
        const capturedImage = webcamRef.current.getScreenshot();
        if (!capturedImage) {
          setloading(false);
          notification("error", "Error al capturar la imagen", "danger");
          return;
        }

        const base64ToBlob = (base64: string, mimeType: string) => {
          const byteString = atob(base64.split(",")[1]);
          const arrayBuffer = new ArrayBuffer(byteString.length);
          const uintArray = new Uint8Array(arrayBuffer);
          for (let i = 0; i < byteString.length; i++) {
            uintArray[i] = byteString.charCodeAt(i);
          }
          return new Blob([arrayBuffer], { type: mimeType });
        };

        const imageBlob = base64ToBlob(capturedImage, "image/jpeg");

        const formData = new FormData();
        formData.append("branchId", String(branch?.id || ""));
        formData.append("checkDate", moment().format("YYYY-MM-DD"));
        formData.append("checkTime", moment().format("HH:mm:ss"));
        formData.append("code", values.code);
        formData.append("photo", imageBlob, "captured-image.jpg");
        formData.append("lastName", values.lastName);

        const response = await registerAssistanceCheckByCode(formData);
        console.log("response", response);
        const partneridMessage =
          response.assistance?.partner?.id || response[0].partner?.id;
        const messageCode = await fetchInfoCode(partneridMessage);
        setMessageWildcards(messageCode.message);
        notification("success", "Registro guardado exitosamente", "success");
        if (response.message) {
          notification("info", response.message, "info");
        }
        formik.resetForm();
        setloading(false);

        setTimeout(() => {
          setMessageWildcards("");
        }, 4000);
      } catch (error) {
        console.error("Hubo un error consulte a soporte", error);
        notification(
          "error",
          "Datos incorrectos, por favor verifique e intente de nuevo",
          "danger"
        );
        setloading(false);
      }
    },
  });
  const captureImage = () => {
    if (webcamRef.current) {
      const imageSrc = webcamRef.current.getScreenshot();
      setCapturedImage(imageSrc);
      notification("info", "Imagen capturada", "info");
    }
  };
  const fetchConfiguration = async () => {
    try {
      const config = await findConfigurationByKey("assistance");
      setConfiguration(config);
      console.log("config", config);
      const branch = await findByName(user.branch);
      console.log("branch", branch);
      setBranch(branch);
    } catch (error) {
      console.error("Error al obtener la configuración:", error);
    }
  };

  const handleScan = async (detectedCodes: IDetectedBarcode[]) => {
    if (!scannerEnabled || detectedCodes.length === 0) return;

    setScannerEnabled(false);

    try {
      const rawValue = detectedCodes[0].rawValue;
      if (!rawValue) {
        throw new Error("No data found in QR code");
      }

      const object = JSON.parse(rawValue);
      console.log(object);
      const { dataQr, buildId, userId } = object;

      const authorizationResponse = await authorizeAssistance(
        dataQr,
        buildId,
        userId
      );

      if (authorizationResponse.status === "Authorized") {
        const { decryptedDataQr } = authorizationResponse.details;
        const { user, timestamp, device, ip, municipio, location } =
          decryptedDataQr;
        const time = new Date(timestamp);

        const expirationTime = new Date(time.getTime() + 10 * 60 * 1000);

        if (expirationTime < new Date()) {
          notification("error", "El código QR ha expirado", "danger");
          return;
        }

        const partner = await fetchPartner(user.id);
        const message = await getMessagePartner(partner.id);

        console.log("Message", message);
        if (isValidForSweetAlert(message)) {
          Swal.fire({
            title: message.title,
            text: message.message,
            icon: message.icon as any,
            showCancelButton: false,
            showConfirmButton: false,
            timer: message.duration * 1000,
            timerProgressBar: true,
          });
        }

        const assistanceObject = {
          branchId: branch?.id,
          checkDate: moment(actualDate).format("YYYY-MM-DD"),
          checkTime: moment(actualDate).format("HH:mm:ss"),
          details: {
            partner: user.id,
            deviceId: device.deviceId,
            deviceModel: device.deviceModel,
            deviceName: device.deviceName,
            ip: ip,
            location: municipio,
            coords: JSON.stringify(location),
          },
        };

        const savedAssistance = await registerAssistance(
          user.id,
          assistanceObject
        );
        if (savedAssistance.message) {
          notification("info", savedAssistance.message, "info");
        }

        console.log("savedAssistance", savedAssistance);

        setTempUser({
          nombre: user.name,
          checkAt: moment(actualDate).format("HH:mm:ss"),

          status:
            savedAssistance.lenth > 0
              ? savedAssistance[0].status
              : savedAssistance.status,
        });

        triggerUpdate({ branchId: Number(branch?.id) });

        notification("Registrado", "Registro gurdado correctamente", "success");
      } else {
        notification(
          "error",
          "No autorizado: el dispositivo no coincide con el registrado",
          "danger"
        );
      }
    } catch (error) {
      console.error("Error al manejar el escaneo:", error);
      notification("error", "Error al procesar el código QR", "danger");
    } finally {
      setTimeout(() => {
        setScannerEnabled(true);
        setTempUser({
          nombre: "",
          checkAt: "",
          status: "",
        });
      }, 3000);
    }
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setActualDate(new Date());
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    fetchConfiguration();
  }, []);

  useEffect(() => {
    console.log(deviceId);
  }, [deviceId]);

  if (!configuration)
    return (
      <div className="row d-flex justify-content-between w-50 mx-auto mt-5">
        <div className="alert alert-warning" role="alert">
          <h4 className="alert-heading">¡Atención!</h4>
          <p>
            No se ha establecido una configuración de hora de llegada máxima y
            mínima de entrada.
          </p>
          <hr />
          <p className="mb-0">
            Por favor, completa estos campos desde el menú configuración para
            poder registrar la asistencia de los trabajadores.
          </p>
        </div>
      </div>
    );

  if (!isConnected) {
    return <Loader isLoading={true} />;
  }

  return (
    <div className="w-100 px-4 mt-1">
      <div className="row flex-column flex-md-row justify-content-md-between w-100">
        <div
          className={`col-md-${
            Boolean(branch?.typeBanner === "text" && branch?.message) ||
            Boolean(branch?.typeBanner === "file" && branch?.imageUrl)
              ? 6
              : 12
          } ps-2 ${
            !(
              Boolean(branch?.typeBanner === "text" && branch?.message) ||
              Boolean(branch?.typeBanner === "file" && branch?.imageUrl)
            ) && "d-flex justify-content-center"
          }`}
        >
          <div className="bg-white py-3 pt-4 pb-2 rounded-3 shadow mb-4 mb-md-0">
            <div className="mx-auto w-75 text-center mb-3">
              <p
                className={`fs-20px ${
                  scannerEnabled ? "text-success" : "text-secondary"
                }`}
              >
                {scannerEnabled ? "Escanea tu QR" : "Escaneado"}
              </p>

              {!modeCode ? (
                <div className="flex-row d-flex justify-content-between mb-3">
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <select
                      value={deviceId}
                      onChange={(e) => {
                        setDeviceId(e.target.value);

                        setShow(false);
                      }}
                      className="form-select rounded-3 bg-light"
                    >
                      <option value={undefined}>
                        {devices.length === 0
                          ? "Cargando..."
                          : "Selecciona un dispositivo"}
                      </option>
                      {devices.map((device, index) => (
                        <option key={index} value={device.deviceId}>
                          {device.label}
                        </option>
                      ))}
                    </select>
                    <button
                      className="btn btn-info ms-2"
                      onClick={() => setRefreshKey((prevKey) => prevKey + 1)}
                    >
                      <i className="fa-solid fa-arrows-rotate"></i>
                    </button>
                  </div>

                  <button
                    className="btn btn-danger p-1 "
                    onClick={() => setModeCode(!modeCode)}
                  >
                    {modeCode ? "Chequeo con QR" : "Chequeo con código"}
                  </button>
                </div>
              ) : (
                <div className="flex-row d-flex justify-content-between mb-3">
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <button
                      className="btn btn-info ms-2"
                      onClick={toggleFacingMode}
                    >
                      <i className="fa-solid fa-arrows-rotate"></i> Cambiar
                      camara
                    </button>
                  </div>

                  <button
                    className="btn btn-danger p-1 "
                    onClick={() => setModeCode(!modeCode)}
                  >
                    {modeCode ? "Chequeo con QR" : "Chequeo con código"}
                  </button>
                </div>
              )}

              {modeCode ? (
                <div className="w-100  mt-5">
                  <CustomInput
                    formik={formik}
                    field="code"
                    label="Código"
                    placeholder="Ingrese su código aquí..."
                    required={true}
                    sm={8}
                    smLabel={4}
                  />
                  <CustomInput
                    formik={formik}
                    field="lastName"
                    label="Primer apellido"
                    placeholder="Ingrese su primer apellido aqui..."
                    sm={8}
                    smLabel={4}
                    required={true}
                  />
                  <div className="mt-2">
                    <Webcam
                      audio={false}
                      ref={webcamRef}
                      screenshotFormat="image/jpeg"
                      className="rounded-3 w-100"
                      videoConstraints={{ facingMode }}
                    />
                  </div>
                  <button
                    className="btn col-12 w-full btn-success mt-1 rounded-3 p-2"
                    onClick={() => formik.handleSubmit()}
                    disabled={loading}
                  >
                    <i className="fas fa-paper-plane"></i> Registrar check
                  </button>

                  {messageWildcards && (
                    <div
                      className="alert justify-content-center text-center alert-primary d-flex mt-2 align-items-center"
                      role="alert"
                    >
                      <i className="fas fa-info-circle me-2"></i>
                      {messageWildcards}
                    </div>
                  )}
                  {/* {capturedImage && (
                 <div className="mt-3">
                   <p>Previsualización de la imagen:</p>
                   <img
                     src={capturedImage}
                     alt="Captura"
                     className="rounded-3"
                     style={{ width: "100%" }}
                   />
                 </div>
               )} */}
                </div>
              ) : (
                scannerEnabled && (
                  <Scanner
                    key={refreshKey}
                    onScan={handleScan}
                    styles={{ container: { width: "100%", borderRadius: 10 } }}
                    constraints={{
                      facingMode: "user",
                    }}
                    onError={(error) => {
                      console.log(`onError: ${error}'`);
                    }}
                  />
                )
              )}
            </div>

            <div className="text-center">
              <p className="display-6 mb-2">
                {moment(actualDate).format("dddd, DD MMMM YYYY")}
              </p>
              <p className="display-1 fw-bold">
                {actualDate.toLocaleTimeString()}
              </p>
            </div>

            {tempUser.nombre && tempUser.checkAt && (
              <div
                className={`mx-auto w-75 mt-4 p-3 rounded-4 shadow text-center ${
                  tempUser.status === "Asistencia" ||
                  tempUser.status === "Salida registrada"
                    ? "bg-success text-white"
                    : tempUser.status === "Retardo"
                    ? "bg-danger text-white"
                    : "bg-secondary text-white"
                }`}
              >
                <h3 className="display-6">{tempUser.nombre}</h3>
                <p className="h4">
                  {/* {tempUser.status === "Salida registrada"
                    ? "Salida"
                    : "Entrada"}{" "} */}
                  Registro guardado correctamente a las:{" "}
                  {actualDate.toLocaleTimeString()}
                </p>
              </div>
            )}
          </div>
        </div>

        {branch?.typeBanner === "text" && branch?.message && (
          <div className="col-md-6 ps-2">
            <div className="bg-light p-4 rounded-3 shadow text-center h-100">
              <h1 className="display-5 fw-semibold">Aviso del día</h1>
              <div dangerouslySetInnerHTML={{ __html: branch.message }} />
            </div>
          </div>
        )}

        {branch?.typeBanner === "file" && branch?.imageUrl && (
          <div className="col-md-6 ps-2">
            <div className="bg-light p-4 rounded-3 shadow text-center h-100">
              <h1 className="display-5 fw-semibold">Aviso del día</h1>
              <img
                src={branch.imageUrl}
                alt="Aviso del día"
                className="img-fluid rounded-3 mt-4"
                style={{ width: "100%", height: "auto" }}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default QrScanner;

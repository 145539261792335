import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IFreeDays, ISchedule } from "../../interfaces/others/ISchedule";

const initialState: ISchedule = {
  freeDays: [],
  fetchFreeDays: false,
  positionsSchedule: [],
  refreshScheduleFunction: () => Promise<void>,
};

const scheduleSlice = createSlice({
  name: "Schedule",
  initialState,
  reducers: {
    addFreeDay: (state, action: PayloadAction<IFreeDays>) => {
      state.freeDays.push(action.payload);
    },
    updateOrAddFreeDay: (state, action: PayloadAction<IFreeDays>) => {
      const index = state.freeDays.findIndex(
        (freeDay) => freeDay.id === action.payload.id
      );

      if (index === -1) {
        state.freeDays.push(action.payload);
      } else {
        state.freeDays[index] = action.payload;
      }
    },
    resetScheduleState: () => initialState,
    setFetchFreeDays: (state) => {
      state.fetchFreeDays = !state.fetchFreeDays;
    },
    setRefreshScheduleFunction: (
      state,
      action
    ) => {
      state.refreshScheduleFunction = action.payload;
    },
    setPositionsSchedule: (state, action) => {
      state.positionsSchedule = action.payload;
    },
  },
});

export const {
  addFreeDay,
  updateOrAddFreeDay,
  resetScheduleState,
  setFetchFreeDays,
  setRefreshScheduleFunction,
  setPositionsSchedule,
} = scheduleSlice.actions;

export default scheduleSlice.reducer;

import { useState } from "react";
import { fetchAssistanceByCode, fetchAssistanceByID } from "../../../services/assistenceCode.ts";


const useFetchCheckDetails = () => {
  const [checkDetails, setCheckDetails] = useState<any[]>([]);
  const [openCheckDetails, setOpenCheckDetails] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const fetchCheckDetails = async (code?: string) => {
    setLoading(true);
    setError(null);
    try {
      const response = await fetchAssistanceByID(code);
      setCheckDetails(response.data);
      setOpenCheckDetails(true);
    } catch (error) {
      console.error("Error al cargar los detalles del check", error);
      setError("Error al cargar los detalles del check");
    } finally {
      setLoading(false);
    }
  };

  return {
    checkDetails,
    openCheckDetails,
    setOpenCheckDetails,
    loading,
    error,
    fetchCheckDetails,
  };
};

export default useFetchCheckDetails;
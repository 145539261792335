import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { logout } from '../../store/slices/authSlice.ts';
import { RootState } from '../../store/index.ts';
import userProfileImg from '../../assets/img/userProfile.jpeg';

const DropdownComponent: React.FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { user } = useSelector((state: RootState) => state.auth);

  return (
    <div className="dropdown">
      <div
        className="dropdown-toggle d-flex align-items-center md-justify-content-center cursor-pointer"
        id="dropdownMenuButton"
        data-bs-toggle="dropdown"
        aria-expanded="false"
      >
        <p className="me-3 mt-3 text-white fw-600 text-center">{user.name} {user.lastName} {user.secondLastName}</p>
        <img src={userProfileImg} alt="" className="rounded-circle me-2" style={{ height: '20px' }} />
      </div>


      <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton">
        {/* <li>
          <a
            className="dropdown-item" href="#"
            onClick={() => navigate("/usuarios/editar/" + user.id)}
          >
            Perfil
          </a>
        </li> */}
      {
        ["Administrador", "Administrador-"].includes(user.rol) &&
          <React.Fragment>
            <li>
              <a
                className="dropdown-item"
                onClick={() => navigate("/reportes")}
              >
                <i className="fa-solid fa-clipboard-list"></i>&nbsp; Reportes
              </a>
            </li>
            <li>
              <a
                className="dropdown-item"
                href="#"
                onClick={() => navigate("/configuracion")}
              >
                <i className="fa-solid fa-gear"></i>&nbsp; Configuración
              </a>
            </li>
            <li>
              <a
                className="dropdown-item"
                href="#"
                onClick={() => navigate("/registro-de-entradas")}
              >
                <i className="fa-solid fa-qrcode"></i>&nbsp; Registros de
                entrada
              </a>
            </li>
          </React.Fragment>
      }
        <li>
          <a
            className="dropdown-item" href="#/"
            onClick={() => {
              dispatch(logout());
              navigate("/");
            }}
          >
            <i className="fa-solid fa-right-from-bracket"></i>&nbsp;Cerrar sesión
          </a>
        </li>
      </ul>
    </div>
  );
};

export default DropdownComponent;

import React from "react";
import { notification } from "../../../../utils/Notifications.tsx";
import Loader from "../../../Loader.tsx";
import { findOperationalIncidents } from "../../../../services/assistanceCheck.service.ts";
import * as moment from "moment-timezone";
import { OperationalUrgency } from "../../../../models/operationalUrgency.ts";
import OperationalUrgencyRow from "./OperationalUrgencyRow.tsx";

const TableOperationalUrgency = () => {
  const [loading, setLoading] = React.useState(true);
  const [data, setData] = React.useState<OperationalUrgency[]>([]);

  const fetchData = async () => {
    try {
      const response = await findOperationalIncidents(
        moment().format("YYYY-MM-DD")
      );
      setData(response);
    } catch (error) {
      notification("error", error.message, "danger");
    } finally {
      setLoading(false);
    }
  };

  React.useEffect(() => {
    fetchData();
  }, []);

  return (
    <div className="table-sticky mb-3">
      <Loader isLoading={loading} />
      <table className="table table-hover table-panel text-nowrap align-middle mb-0">
        <thead className="sticky-thead table-light text-center">
          <tr>
            <th className="align-middle">Nombre</th>
            <th className="align-middle">Faltas</th>
            <th className="align-middle">Minutos sin asignar</th>
            <th className="align-middle">Tiempo sin cubrir</th>
            <th className="align-middle">Tiempo total</th>
            <th className="align-middle">Cubierto</th>
          </tr>
        </thead>
        <tbody className="accordion" id="accordionExample">
          {data.map((item, index) => (
            <OperationalUrgencyRow item={item} no={index} key={index} />
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default TableOperationalUrgency;

export const CustomButton = ({
  text,
  style,
  to,
}: {
  text: string;
  style: string;
  to?: string;
}) => {
  return (
    <button
      type="button"
      className={`btn btn-${style} d-block btn-lg fs-14px`}
      style={{ margin: "0 auto" }}
      onClick={() => to && window.open(to, "_blank")}
    >
      {text}
    </button>
  );
};
